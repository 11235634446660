import {createTheme} from '@mui/material/styles';

export default createTheme({
    typography: {
        fontFamily: 'Epilogue, sans-serif'
    },
    palette: {
        primary: {
            main: '#FF5A3C',
        },
        secondary: {
            main: '#f82300',
        },
        background: {
            paper: '#fff',
        },
    },
    components: {
        MuiAutocomplete: {
            styleOverrides: {
                input: {
                    textTransform: 'capitalize'
                },
                option: {
                    textTransform: 'capitalize'
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                select:{
                    textTransform: 'capitalize'
                },
                input: {
                    textTransform: 'capitalize'
                },
                option: {
                    textTransform: 'capitalize'
                },
                root: {
                    zIndex: 999,
                    textTransform: 'capitalize'
                }
            }
        }
    }
});
