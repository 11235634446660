import React, {useState} from 'react';
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import httpService from "../../services/httpService";
import {
    setUserActive,
    setUserEmail,
    setUserFirstname,
    setUserLogged,
    updateUserData
} from "../../redux/actions/userActions";
import CreateValidator from "../../utils/validator";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Typography from "@mui/material/Typography";
import {ValidationForm} from "../../components/common/ValidationForm";
import InputRadioGroup from "../../components/common/InputRadioGroup";
import {ACCOUNT} from "../../enums/account";
import InputText from "../../components/common/InputText";
import LoadingButton from "@mui/lab/LoadingButton";
import validationSchema from "../../validation/updateAccount";
import InputCheckbox from "../../components/common/InputCheckbox";
import toast from "../../utils/toast";

const UserAccountPage = () => {
    const user = useSelector((state) => state.user);

    const history = useHistory();
    const dispatch = useDispatch();

    const translations = {
        title: "Actualizare cont",
        labels: {
            account: "Tip de cont",
            firstname: "Prenume",
            lastname: "Nume",
            email: "Adresa de email",
            submit: "Actualizeaza"
        }
    }

    const handleSubmit = (data, actions) => {
        httpService
            .post('/user/account', data)
            .then(({data}) => {
                const { firstname, lastname, account } = data;
                dispatch(updateUserData({ firstname, lastname, account }));
                toast.success('Cont', 'Contul a fost actualizat.');
            })
            .catch(error => {
                if (error.response?.data?.formik) {
                    actions.setErrors(error.response.data?.formik);
                    actions.setSubmitting(false);
                }
            })
    }

    const validator = CreateValidator({
        ...validationSchema,
        initialValues:
            {  ...validationSchema.initialValues, ...user,}
    }, handleSubmit);

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <Box
                sx={{
                    margin: '50px 0',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                    <AccountCircleIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    {translations.title}
                </Typography>

                <ValidationForm validator={validator}>
                    <InputRadioGroup
                        disabled={validator.isSubmitting}
                        name={validationSchema.fieldNames.account}
                        label={translations.labels.account}
                        values={[
                            {label: 'Persoana fizica', value: ACCOUNT.INDIVIDUAL},
                            {label: 'Agentie', value: ACCOUNT.AGENCY},
                        ]}
                    />
                    <InputText
                        disabled={validator.isSubmitting}
                        name={validationSchema.fieldNames.firstname}
                        label={translations.labels.firstname}
                    />
                    <InputText
                        disabled={validator.isSubmitting}
                        name={validationSchema.fieldNames.lastname}
                        label={translations.labels.lastname}
                    />
                    <InputText
                        disabled
                        name={validationSchema.fieldNames.email}
                        label={translations.labels.email}
                        helperText="Nu se poate modifica"
                    />
                    <LoadingButton
                        disabled={!validator.dirty || validator.isSubmitting}
                        loading={ validator.isSubmitting }
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{mt: 3, mb: 2}}
                    >
                        {translations.labels.submit}
                    </LoadingButton>
                </ValidationForm>
            </Box>
        </Container>
    );
};

export default UserAccountPage;