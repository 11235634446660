import React, {useEffect, useRef, useState} from 'react';
import {
    Autocomplete,
    FilledInput,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    TextField,
    Typography
} from "@mui/material";
import {baths, comfort, kitchens, rooms, searchTypes} from "../../../dropdowns/search";
import {makeStyles} from "@mui/styles";

const cities = {
    'bucuresti ilfov': ['bucuresti'],
    'brasov': ['brasov'],
    'timis': ['timisoara']
}

const useStyles = makeStyles(() => ({
    autocompleteInput: {
        textTransform: 'capitalize'
    }
}));

const dropdowns = {
    category: searchTypes,
    counties: ['bucuresti ilfov', 'brasov', 'timis'],
    rooms: rooms,
    baths: baths,
    kitchens: kitchens,
    comfort: comfort,
}

const Localization = ({ active, currentSearch, handleChange }) => {

    const styles = useStyles();
    const [citiesDropdown, setCitiesDropdown] = useState([cities[dropdowns.counties[0]]]);

    const initialRender = useRef(true);

    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false;
        } else {
            if (currentSearch.county) {
                setCitiesDropdown(cities[currentSearch.county]);
            }
            handleChange({
                target: {
                    name: 'city',
                    value: cities[currentSearch.county][0]
                }
            })
        }

    }, [currentSearch.county]);


    if (!active) return null;

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            maxWidth="sm"
        >
            <Grid item>
                <Typography sx={{mt: 1}} variant="h5" textAlign="center">Localizare</Typography>
                <Typography sx={{mb: 1}} variant="subtitle2" textAlign="center">
                    Avem nevoie de judetul si orasul in care cauti
                </Typography>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Autocomplete
                        disableClearable
                        className={styles.autocompleteInput}
                        value={currentSearch.county}
                        options={[...dropdowns.counties]}
                        onChange={(event, newValue) => {
                            handleChange({target: {name: 'county', value: newValue}});
                        }}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                className={styles.autocompleteInput}
                                label="Judet"
                                variant="filled"
                            />
                        }
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Autocomplete
                        disableClearable
                        value={currentSearch.city}
                        options={citiesDropdown || []}
                        onChange={(event, newValue) => {
                            handleChange({target: {name: 'city', value: newValue}});
                        }}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                className={styles.autocompleteInput}
                                label={currentSearch.county === "bucuresti" ? 'Localitate/Sector' : 'Localitate'}
                                variant="filled"
                            />}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Localization;