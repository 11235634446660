
import * as yup from "yup";

export const initialValues = {
    pin: ''
}

export const validationSchema = yup.object().shape({
    pin: yup.string().required('Codul pin este obligatoriu').length(6, 'Trebuie sa fie de 6 charactere'),
});

export const fieldNames = {
    pin: 'pin',
}

export default {initialValues, validationSchema, fieldNames};