import React, {useEffect, useState} from 'react';
import CallToActionV1 from '../components/template/section-components/call-to-action-v1';
import httpService from "../services/httpService";
import {useParams} from "react-router-dom";
import {capitalize, CircularProgress, Grid, Typography} from "@mui/material";
import ListingDetails from "../components/listing/ListingDetails/ListingDetails";
import Seo from "../components/SEO/SEO";

const ListingDetailsPage = () => {
    const {id} = useParams();
    const [loading, setLoading] = useState(true);
    const [listing, setListing] = useState({});

    useEffect(() => {
        httpService
            .get(`/listing/${id}`)
            .then(({data}) => {
                setListing(data.listing);
                setLoading(false);
            });
    }, [id]);

    if (loading) return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '90vh' }}
        >
            <Grid item xs={3}>
                <CircularProgress />
            </Grid>
            <Grid item>
                <Typography variant="h5">Se incarca</Typography>

            </Grid>
        </Grid>
    );

    const descriptionTrimmed =  listing.description?.slice(0,155);
    const seoDescription = descriptionTrimmed.substring(0, Math.min(descriptionTrimmed.length, descriptionTrimmed.lastIndexOf(" "))) + '...';

    return (
        <>
            {/*<PageHeader*/}
            {/*  headertitle="Product Details"*/}
            {/*  customclass="mb-0"*/}
            {/*/>*/}
            <Seo
                title={`${capitalize(listing?.title)}`}
                description={capitalize(seoDescription)}
            />
            <ListingDetails listing={listing}/>
            <CallToActionV1/>
        </>
    )
}

export default ListingDetailsPage

