import countiesWithCitiesJSON from '../data/regionsCities.json';
import countiesJSON from '../data/regions.json';

export const counties = countiesJSON;
export const getCities = (county) => countiesWithCitiesJSON[county];

export const defaults = {
  county: "brasov",
  city: "brasov"
}
