import React from 'react';
import {Link} from "react-router-dom";
import pathService from "../../services/pathService";

const CallAction = () => {
    return (
        <div className="ltn__call-to-action-area call-to-action-6 before-bg-bottom bg-light" >
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="call-to-action-inner call-to-action-inner-6 ltn__secondary-bg position-relative text-center---">
                            <div className="coll-to-info text-color-white">
                                <h1>Cauti casa sau apartamentul visurilor?</h1>
                                <p>Noi te putem ajuta! Iti punem la disponibilitate toate proprietatiile din Romania!</p>
                            </div>
                            <div className="btn-wrapper go-top">
                                <Link className="btn btn-effect-3 btn-white" to={pathService.build.search({service: 'vanzare', category: 'apartamente', county: 'toate', city: 'toate'})}>Vezi properietatiile <i className="icon-next" /></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CallAction;