import React from 'react';
import CheckIcon from '@mui/icons-material/CheckCircle';
import RemoveIcon from '@mui/icons-material/HighlightOff';
import {v4 as uuid} from 'uuid';

const Utilities = ({ listing }) => {
    const valueMapper = {
        true: <CheckIcon style={{ color: 'green' }} />,
        false: <RemoveIcon style={{ color: 'red' }} />
    };

    const mapper = {
        power: "Curent",
        sewage: "Canalizare",
        gas: "Gaz",
        phone: "Telefon",
        centralHeating: "Centrala termica",
        internetFiber: "Fibra internet",
        asphalt: "Asfalt",
        catv: "Cablu TV",
        elevator: "Lift",
        streetLights: "Lumini stradale",
        transportation: "Transport comun",
        water: "Apa"
    };

    const UtilitiesList = Object.keys(listing.utilities)
        .map(item => <li key={item}>{valueMapper[listing.utilities[item]]} {mapper[item]}</li>);

    const Columns = {
        first: UtilitiesList.slice(0, 4),
        second: UtilitiesList.slice(4, 8),
        third: UtilitiesList.slice(8, 12),
    }

    const ListElement = [Columns.first, Columns.second, Columns.third].map(item => (
        <div className="col-lg-4 col-md-6" key={uuid()}>
            <div className="ltn__menu-widget">
                <ul>
                    {item}
                </ul>
            </div>
        </div>
    ))

    return (
        <div>
            <h4 className="title-2 mb-10">Utilitati</h4>
            <div className="property-details-amenities mb-60">
                <div className="row">
                    { ListElement }
                </div>
            </div>
        </div>
    );
};

export default Utilities;