export const SET_SEARCH = 'SET_SEARCH';
export const SET_TRIGGER_SEARCH_CHANGE = 'SET_TRIGGER_SEARCH_CHANGE';
export const CLEAR_SEARCH = 'CLEAR_SEARCH';

export const ADD_FAVOURITE = 'ADD_FAVOURITE';

export const SET_OPEN_LISTING_VIEW = 'SET_OPEN_LISTING_VIEW';
export const SET_CLOSE_LISTING_VIEW = 'SET_CLOSE_LISTING_VIEW';
export const SET_LISTING_VIEW = 'SET_LISTING_VIEW';

export const LIGHTBOX_SET_OPEN = 'LIGHTBOX_SET_OPEN';
export const LIGHTBOX_IMAGES = 'LIGHTBOX_IMAGES';
export const LIGHTBOX_INDEX = 'LIGHTBOX_INDEX';

export const SET_USER_EMAIL = 'SET_USER_EMAIL';
export const SET_USER_ACTIVE = 'SET_USER_ACTIVE';
export const SET_USER_LOGGED = 'SET_USER_LOGGED';
export const SET_USER_SETTINGS = 'SET_USER_SETTINGS';
export const SET_USER_LISTINGS = 'SET_USER_LISTINGS';
export const SET_USER_PENDING_LISTINGS = 'SET_USER_PENDING_LISTINGS';
export const SET_USER_SAVED = 'SET_USER_SAVED';
export const ADD_USER_SAVED = 'ADD_USER_SAVED';
export const ADD_LOCAL_USER_SAVED = 'ADD_LOCAL_USER_SAVED';
export const SET_USER_TYPE = 'SET_USER_TYPE';
export const SET_USER_LASTNAME = 'SET_USER_LASTNAME';
export const SET_USER_FIRSTNAME = 'SET_USER_FIRSTNAME';
export const SET_REMOVE_USER_SAVED = 'SET_REMOVE_USER_SAVED';
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';

export const REMOVE_USER_SAVED = 'REMOVE_USER_SAVED';
export const REMOVE_USER_LOCAL_SAVED = 'REMOVE_USER_LOCAL_SAVED';

export const USER_LOGOUT = 'USER_LOGOUT';

export const SET_REDIRECT = 'SET_REDIRECT';

export const SET_OPEN_MOBILE_MENU = 'SET_OPEN_MOBILE_MENU';
export const SET_OPEN_DRAWER_USER = 'SET_OPEN_DRAWER_USER';
export const SET_OPEN_DRAWER_MENU_MOBILE = 'SET_OPEN_DRAWER_MENU_MOBILE';

export const SET_OPEN_INTELLIGENT_SEARCH = 'SET_OPEN_INTELLIGENT_SEARCH';
export const ADD_SESSION_INTELLIGENT_SEARCH = 'ADD_SESSION_INTELLIGENT_SEARCH';