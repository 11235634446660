import React, {useEffect, useState} from 'react';

// COMPONENTS
import Filters from '../components/search/Filters';
import ListingsContainer from '../components/listing/ListingsContainer';
import InfiniteScroll from 'react-infinite-scroll-component';
import ScrollToTop from "react-scroll-to-top";

import {useHistory, useParams} from 'react-router-dom';
import {SEARCH_DISPLAY} from '../types/search';

// SERVICES
import httpService from '../services/httpService';
import {Backdrop, Box, capitalize, CircularProgress, Grid, Typography} from '@mui/material';

import {CheckCircleRounded, Refresh} from '@mui/icons-material';
import {useDispatch, useSelector} from "react-redux";
import {setTriggerSearchChange} from "../redux/actions/dataActions";
import MapViewContainer from "../components/listing/MapViewContainer";
import ListingViewModal from "../components/listing/ListingViewModal";
import Lightbox from "../components/listing/ListingDetails/ListingDetails/Lightbox";
import useGA from "../hooks/analytics";
import Seo from "../components/SEO/SEO";

const SearchPage = () => {
    const dispatch = useDispatch();
    const ga = useGA('Search');

    const [openBackdrop, setOpenBackdrop] = useState(false);

    const {search: searchData, searchUpdated} = useSelector((state) => state.data);

    const {service, category, county, city} = useParams();

    const [search, setSearch] = useState({
        ...(searchData ?? {}),
        service, category, county, city,
    });

    const [isAlternatives, setIsAlternatives] = useState(false);
    const [results, setResults] = useState([]);
    const [searchDisplay, setSearchDisplay] = useState(SEARCH_DISPLAY.DEFAULT);

    // Pagination
    const [totalResults, setTotalResults] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [offset, setOffset] = useState(0);
    const limit = 36;

    /**
     * Handle change search display
     * @param option
     * @returns {*}
     */
    const handleChangeSearchDisplay = (option) => setSearchDisplay(option);

    const fetchData = async (data) => {
        const res = await httpService.get('/search', {params: {...data, offset, limit}});
        const {results, hasMore, totalResults, alternatives} = res.data;
        setIsAlternatives(alternatives);
        setOffset(0);
        setResults(results);
        setTotalResults(totalResults);
        setHasMore(hasMore);
    }

    const fetchNextData = async () => {
        const res = await httpService.get('/search', {params: {...search, offset, limit}});
        const {results, hasMore, totalResults, alternatives} = res.data;
        setIsAlternatives(alternatives);
        setOffset(prevOffset => prevOffset + limit);
        setResults(prevResults => {
            return prevResults.concat(results)
            // if (prevResults?.length > 50) {
            //     return prevResults.splice(0, 18).concat(results);
            // } else {
            //     return prevResults.concat(results)
            // }
        });
        setTotalResults(totalResults);
        setHasMore(hasMore);
    };

    // useEffect(() => {
    //     history.replace({pathname: pathService.build.search(search)});
    // }, [search]);

    useEffect(() => {
        fetchNextData().then();
    }, []);

    useEffect(() => {
        if (searchUpdated) {
            setOpenBackdrop(true);
            setSearch(searchData);
            fetchData(searchData).then(() => setOpenBackdrop(false));
            dispatch(setTriggerSearchChange(false));
        }

    }, [searchUpdated]);

    const title = search.county === 'toate' ? `${capitalize(search.service)} ${search.category} din Romania - Caseplex` : `${capitalize(search.service)} ${search.category} ${search.city === 'toate' ? '' : capitalize(search.city)} ${capitalize(search.county)} - Caseplex`

    return (
        <>
            <Seo
                title={title}
                description={`Cauti ${search.category} in ${search.county === 'toate' ? 'Romania' :capitalize(search.city)}? Noi avem exact ce cauti! Platforma noastra analizeaza fiecare imobil si iti spune daca este alegerea corecta.`}
            />
            { isAlternatives && (
                <>
                    <Refresh fontSize="large" sx={{color: 'primary.main'}}/>
                    Nu au fost gasite anunturi in orasul respectiv. Dar iti aratam niste alternative bune:
                </>
            )}
            { searchDisplay === SEARCH_DISPLAY.MAP && (
                <>
                    <div className="ltn__product-area ltn__product-gutter">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <Filters totalResults={totalResults.toLocaleString('ro-RO')}
                                             searchDisplay={searchDisplay}
                                             handleChangeSearchDisplay={handleChangeSearchDisplay}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <MapViewContainer listings={results} search={search} />
                    <ListingViewModal />
                    <Lightbox />
                </>
            )}
            { searchDisplay !== SEARCH_DISPLAY.MAP && (<ScrollToTop smooth />) }
            { searchDisplay !== SEARCH_DISPLAY.MAP && (
                <InfiniteScroll
                    dataLength={results.length}
                    next={fetchNextData}
                    hasMore={hasMore}
                    loader={
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            flexDirection="column"
                            sx={{width: '100%', margin: '30px 0'}}
                        >
                            <p>Se incarca mai multe anunturi</p>
                            <CircularProgress size={80}/>
                        </Box>
                    }
                    endMessage={
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            flexDirection="column"
                            sx={{width: '100%', margin: '30px 0'}}
                        >
                            { results.length === 0 && 'Nu exista resultate.' }
                            { results.length !== 0 && (
                                <>
                                    <CheckCircleRounded fontSize="large" sx={{color: 'primary.main'}}/>
                                    Acestea au fost toate rezultatele.
                                </>
                            ) }
                        </Box>
                    }
                >
                    <div className="ltn__product-area ltn__product-gutter mb-100">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <Filters totalResults={totalResults.toLocaleString('ro-RO')}
                                             searchDisplay={searchDisplay}
                                             handleChangeSearchDisplay={handleChangeSearchDisplay}/>
                                    <div className="tab-content">
                                        <ListingsContainer display={searchDisplay} listings={results}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </InfiniteScroll>
            )}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openBackdrop}
            >
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid item xs={3}>
                        <CircularProgress color="inherit" />
                    </Grid>
                    <Grid item>
                        <Typography variant="h5" color="inherit">Se cauta anunturi cu noile filtre</Typography>
                    </Grid>
                </Grid>
            </Backdrop>
        </>
    );
}

export default SearchPage;
