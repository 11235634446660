import React, {createContext} from 'react';
import {Box} from "@mui/material";

const ValidatorContext = createContext(undefined);

const ValidationForm = ({validator, children}) => {
    return (
        <ValidatorContext.Provider value={validator}>
            <Box component="form" onSubmit={validator.handleSubmit} sx={{mt: 1}}>
                {children}
            </Box>
        </ValidatorContext.Provider>
    );
};

export {ValidationForm, ValidatorContext };