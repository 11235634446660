import {SET_OPEN_DRAWER_MENU_MOBILE, SET_OPEN_DRAWER_USER, SET_OPEN_MOBILE_MENU} from '../types';

const initialState = {
    openMobileMenu: false,
    drawer: {
        user: false,
        menuMobile: false,
    }
};
/**
 * data reducer
 * @param state
 * @param action
 * @return {{search: {}}}
 */
export default function (state = initialState, action) {
    switch (action.type) {
        case SET_OPEN_MOBILE_MENU:
            return {
                ...state,
                openMobileMenu: action.payload,
            };
        case SET_OPEN_DRAWER_USER:
            return {
                ...state,
                drawer: {
                    ...state.drawer,
                    user: action.payload,
                }
            }
        case SET_OPEN_DRAWER_MENU_MOBILE:
            return {
                ...state,
                drawer: {
                    ...state.drawer,
                    menuMobile: action.payload,
                }
            }
        default:
            return state;
    }
}
