import React from 'react';
import Activation from "../components/containers/ActivationPage/Activation";

const ActivationPage = () => {
    return (
        <>
            <Activation />
        </>
    );
};

export default ActivationPage;