import {
    ADD_LOCAL_USER_SAVED,
    ADD_USER_SAVED, REMOVE_USER_LOCAL_SAVED, REMOVE_USER_SAVED, SET_REDIRECT, SET_REMOVE_USER_SAVED,
    SET_USER_ACTIVE,
    SET_USER_EMAIL, SET_USER_FIRSTNAME, SET_USER_LASTNAME,
    SET_USER_LISTINGS,
    SET_USER_LOGGED, SET_USER_PENDING_LISTINGS, SET_USER_SAVED,
    SET_USER_SETTINGS, SET_USER_TYPE, UPDATE_USER_DATA, USER_LOGOUT
} from '../types';
import httpService from "../../services/httpService";
import authService from "../../services/authService";
import toast from "../../utils/toast";
import pathService from "../../services/pathService";

export const addLocalUserSaved = (data) => (dispatch) => dispatch({ type: ADD_LOCAL_USER_SAVED, payload: data });
export const setUserEmail = (data) => (dispatch) => dispatch({ type: SET_USER_EMAIL, payload: data });
export const setUserActive = (data) => (dispatch) => dispatch({ type: SET_USER_ACTIVE, payload: data });
export const setUserLogged = (data) => (dispatch) => dispatch({ type: SET_USER_LOGGED, payload: data });
export const setUserSettings = (data) => (dispatch) => dispatch({ type: SET_USER_SETTINGS, payload: data });
export const setUserListings = (data) => (dispatch) => dispatch({ type: SET_USER_LISTINGS, payload: data });
export const setUserPendingListings = (data) => (dispatch) => dispatch({ type: SET_USER_PENDING_LISTINGS, payload: data });
export const setUserSaved = (data) => (dispatch) => dispatch({ type: SET_USER_SAVED, payload: data });
export const setUserType = (data) => (dispatch) => dispatch({ type: SET_USER_TYPE, payload: data });
export const setUserFirstname = (data) => (dispatch) => dispatch({ type: SET_USER_FIRSTNAME, payload: data });
export const setUserLastname = (data) => (dispatch) => dispatch({ type: SET_USER_LASTNAME, payload: data });
export const updateUserData = (data) => (dispatch) => dispatch({ type: UPDATE_USER_DATA, payload: data });
export const addUserSaved = (data) => (dispatch) => dispatch({type: ADD_USER_SAVED, payload: data});
export const removeUserSaved = (data) => (dispatch) => dispatch({ type: REMOVE_USER_SAVED, payload: data});
export const removeUserLocalSaved = (data) => (dispatch) => dispatch({ type: REMOVE_USER_LOCAL_SAVED, payload: data});
export const setRemoveUserSaved = (data) => (dispatch) => dispatch({ type: SET_REMOVE_USER_SAVED, payload: data});

export const userLogout = () => (dispatch) => {
    httpService
        .get(pathService.api.logout)
        .then(() => {
            dispatch({type: USER_LOGOUT});
            toast.success('Delogare', 'Ai fost delogat');
        })
        .catch(() => {
            dispatch({type: USER_LOGOUT});
        })
};

export const sessionExpired = () => (dispatch) => {
    dispatch({type: USER_LOGOUT});
    dispatch({type: SET_REDIRECT, payload: pathService.pages.login })
    toast.error('', 'Sesiunea a expirat te rugam sa te loghezi din nou');
}

export const getUser = () => (dispatch) => {
    httpService
        .get('/user')
        .then(({data}) => {
            dispatch(setUserActive(data.active));
            dispatch(setUserEmail(data.email));
            dispatch(setUserSettings(data.settings));
            dispatch(setUserListings(data.listing));
            dispatch(setUserPendingListings(data.pendingListings));
            dispatch(setUserSaved(data.saved));
            dispatch(setUserType(data.userType));
            dispatch(setUserFirstname(data.firstname));
            dispatch(setUserLastname(data.lastname));
            dispatch(setUserLogged(true));
    })
}

export const loginUser = (data) => (dispatch) => {
    authService.setToken(data.token);
    authService.setRefreshToken(data.refreshToken);
    dispatch(getUser());
}