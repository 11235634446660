import React from 'react';

const Form = ({ children, handleSubmit, submitButtonText }) => {
  return (
    <div className="car-dealer-form-inner">
      <div className="ltn__car-dealer-form-box row">
        {children}
        <div className="col-lg-12">
          <div className="btn-wrapper text-center go-top">
            <button
              className="btn theme-btn-1 btn-effect-1 text-uppercase"
              onClick={() => handleSubmit()}
            >
              {submitButtonText}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Form;
