import { SET_LISTING_VIEW, SET_OPEN_LISTING_VIEW, SET_CLOSE_LISTING_VIEW } from '../types';

const initialState = {
    open: false,
    listing: null,
};
/**
 * data reducer
 * @param state
 * @param action
 * @return {{search: {}}}
 */
export default function(state = initialState, action) {
    switch (action.type) {
        case SET_LISTING_VIEW:
            return {
                ...state,
                listing: action.payload,
            };
        case SET_OPEN_LISTING_VIEW:
            return {
                ...state,
                open: true,
            }
        case SET_CLOSE_LISTING_VIEW:
            return {
                ...state,
                open: false,
            }
        default:
            return state;
    }
}
