import React from 'react';
import Register from "../components/containers/RegisterPage/Register";

const RegisterPage = () => {
  return (
      <Register />
  )
}

export default RegisterPage;

