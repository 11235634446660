const service = {};

export const keys = {
    token: 'token',
    refreshToken: 'refreshToken',
}

service.setToken = (token) => localStorage.setItem(keys.token, token);
service.setRefreshToken = (refreshToken) => localStorage.setItem(keys.refreshToken, refreshToken);
service.getToken = () => localStorage.getItem(keys.token);
service.getRefreshToken = () => localStorage.getItem(keys.refreshToken);

export default service;