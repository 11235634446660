import React from 'react';
import {Typography} from "@mui/material";

const UserAddListing = () => {
    return (
        <>
            <Typography variant="h4" textAlign="center" style={{ minHeight: '70vh', marginTop: 50}}>Momentan aceasta pagina este in lucru.</Typography>
        </>
    );
};

export default UserAddListing;