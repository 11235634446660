import React from 'react';

const FormItem = ({ className, children, sizeLg = 12, sizeMd = 12, sizeSm = 12 }) => {
  return (
    <div
      className={ `ltn__car-dealer-form-item ltn__custom-icon col-lg-${sizeLg} col-md-${sizeMd} col-sm-${sizeSm} ${className}` }>
      { children }
    </div>
  )
}

export default FormItem;
