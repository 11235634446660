import React, {useEffect} from "react";
import ReactGA from "react-ga4";

const TRACKING_ID = 'G-F0116S7C0T';

function useGA(category) {

    useEffect(() => {
        ReactGA.initialize(TRACKING_ID);
        ReactGA.send("pageview");
    }, []);

    return {
        event: (action = "test action") => {
            ReactGA.event({category, action});
        },
    };
}

export default useGA;