import React from 'react';
import {Link} from "react-router-dom";
import {Button, capitalize, Typography} from "@mui/material";
import pathService from "../../../../services/pathService";
import Services from "../../../../enums/services";

const Meta = ({listing}) => {
    return (
        <>
            <div className="ltn__blog-meta">
                <ul>
                    <li className="ltn__blog-category">
                        <Link to="#">Detalii</Link>
                    </li>
                    <li className="ltn__blog-category">
                        <Link className="bg-orange" to="#">De {listing.service}</Link>
                    </li>
                    <li className="ltn__blog-category">
                        <Link className="bg-green" to={pathService.build.listingDetails(listing)} target="_blank">Vezi
                            anunt</Link>
                    </li>
                    <li className="ltn__blog-date">
                        <i className="far fa-calendar-alt"/>Adaugat
                        pe {listing.updatedAt ? new Date(listing.updatedAt).toLocaleDateString() : new Date().toLocaleDateString('ro-RO')}
                    </li>
                </ul>
            </div>

            <h1>{listing.title}</h1>
            <label><span className="ltn__secondary-color"><i
                className="flaticon-pin"/></span> {capitalize(listing.area ? `${listing.area}, ` : '')} {capitalize(listing.city || '')}, {capitalize(listing.county || '')}
            </label>
            <h4 className="pricing-title ltn__secondary-color">PRET: {listing.price.toLocaleString('ro-RO')} <label> EURO{listing.service === Services.RENT && ' / luna'}</label></h4>

        </>
    );
};

export default Meta;