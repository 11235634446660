import Swal from "sweetalert2";

const toast = {};

const defaultOpt = { toast: false, timer: 4000, timerProgressBar: true, showConfirmButton: false}
const types = {
    success: { icon: 'success', type: 'success' },
    error: { icon: 'error', type: 'error' },
    warn: { icon: 'warning', type: 'warning' },
    info: { icon: 'info', type: 'info' },
}

toast.success = (title, text, opt = defaultOpt) => {
    Swal.fire({
        title, text,
        ...types.success,
        ...opt,
    });
}

toast.error = (title, text, opt = defaultOpt) => {
    Swal.fire({
        title, text,
        ...types.error,
        ...opt,
    })
}

toast.warn = (title, text, opt = defaultOpt) => {
    Swal.fire({
        title, text,
        ...types.warn,
        ...opt,
    })
}

toast.info = (title, text, opt = defaultOpt) => {
    Swal.fire({
        title, text,
        ...types.info,
        ...opt,
    })
}

export default toast;