import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import httpService from '../../../services/httpService';
import {useDispatch, useSelector} from "react-redux";
import {loginUser} from "../../../redux/actions/userActions";
import {useHistory} from "react-router-dom";
import {ValidationForm} from "../../common/ValidationForm";
import InputText from "../../common/InputText";
import LoadingButton from "@mui/lab/LoadingButton";
import pathService from "../../../services/pathService";
import CreateValidator from "../../../utils/validator";
import validationSchema from "../../../validation/login";
import {useEffect} from "react";
import { GoogleLogin } from 'react-google-login';
import { gapi } from 'gapi-script';
import toast from "../../../utils/toast";

export default function Login() {

    const history = useHistory();
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const clientId = '806975370191-ol666t67h0kfnqr2hfu9hmitvj66mb4l.apps.googleusercontent.com';

    useEffect(() => {
        const initClient = () => {
            gapi.client.init({
                clientId: clientId,
                scope: ''
            });
        };
        gapi.load('client:auth2', initClient);
    });

    const onSuccess = ({ tokenId }) => {
        httpService
            .post(pathService.api.loginGoogle, { token: tokenId })
            .then(({data}) => {
                dispatch(loginUser(data, history));
            })
            .catch(res => {
                toast.error('Ceva nu a mers bine. Te rog sa reincerci.');
            })
    };
    const onFailure = (err) => {
        toast.error('Ceva nu a mers bine...')
    };

    const translations = {
        title: "Logare",
        labels: {
            email: "Adresa de email",
            password: "Parola",
            remember: "Tine-ma minte",
            submit: "Logare",
            forgot: "Ai uitat parola?",
            register: "Inregistreaza-te"
        }
    }

    useEffect(() => {
        if (user.logged) {
            if (user.active) {
                if (history.action !== 'POP') return history.goBack();
                return history.push(pathService.pages.home);
            }
            else return history.push(pathService.pages.activation)
        }
    }, [user])

    const handleSubmit = (data, actions) => {
        actions.setSubmitting(true);
        httpService
            .post(pathService.api.login, data)
            .then(({data}) => {
                dispatch(loginUser(data, history));
            })
            .catch(() => {
                actions.setErrors({email: 'Email-ul sau parola sunt gresite'});
                actions.setSubmitting(false);
            })
    }

    const validator = CreateValidator(validationSchema, handleSubmit);

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <Box
                sx={{
                    margin: '50px 0',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    {translations.title}
                </Typography>
                <ValidationForm validator={validator}>
                    <InputText
                        disabled={validator.isSubmitting}
                        name={validationSchema.fieldNames.email}
                        label={translations.labels.email}
                        autoComplete="email"
                    />
                    <InputText
                        disabled={validator.isSubmitting}
                        password
                        name={validationSchema.fieldNames.password}
                        label={translations.labels.password}
                    />
                    <LoadingButton
                        disabled={!validator.dirty || validator.isSubmitting}
                        loading={validator.isSubmitting}
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{mt: 3, mb: 2}}
                    >
                        {translations.labels.submit}
                    </LoadingButton>
                </ValidationForm>
                <GoogleLogin
                    clientId={clientId}
                    buttonText="Logare cu Google"
                    onSuccess={onSuccess}
                    onFailure={onFailure}
                    cookiePolicy={'single_host_origin'}
                    isSignedIn={false}
                />
            </Box>
        </Container>
    );
}