import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setIndexLightbox, setOpenLightbox} from "../../../../redux/actions/lightboxActions";
import LightboxComp from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const Lightbox = () => {
    const dispatch = useDispatch();
    const { open, images, index } = useSelector((state) => state.lightbox);
    return (
        <>
            { open && (
                <LightboxComp
                    mainSrc={images[index]}
                    nextSrc={images[(index + 1) % images.length]}
                    prevSrc={images[(index + images.length - 1) % images.length]}
                    onCloseRequest={() => dispatch(setOpenLightbox(false))}
                    onMovePrevRequest={() =>
                        dispatch(setIndexLightbox((index + images.length - 1) % images.length))
                    }
                    onMoveNextRequest={() =>
                        dispatch(setIndexLightbox((index + 1) % images.length))
                    }
                />
            )}
        </>
    );
};

export default Lightbox;