import { generatePath } from 'react-router';
import services from '../enums/services';
import { TYPE } from '../enums/search';

const paths = {
  api: {
    getSaved: '/user/saved',
    getLocalSaved: '/data/listings',
    removeSaved: '/user/saved',
    addSaved: '/user/saved',
    logout: '/logout',
    login: '/login',
    loginGoogle: '/login/google',
    refreshToken: '/refreshToken'
  },
  pages: {
    intelligentSearch: {
      results: '/cautare-inteligenta/rezultate'
    },
    user: {
      saved: '/cont/salvate',
      account: '/cont',
      addListing: '/cont/anunturi/adaugare',
    },
    home: '/',
    login: '/logare',
    register: '/inregistrare',
    search: '/cauta/:service/:category/:county/:city',
    listingDetails: '/anunturi/:service/:category/:county/:city/:id',
    activation: '/activare',
    agents: '/agenti',
    developers: '/dezvoltatori',
    contact: '/contact'
  },

  build: {
    search: ({ service, category, county, city }) => generatePath('/cauta/:service/:category/:county/:city', { service, category, county: county || 'toate', city: city || 'toate' }),
    listingDetails: ({ service, category = 'apartamente', county, city, id }) => generatePath('/anunturi/:service/:category/:county/:city/:id', { service, category, county, city, id })
  }
}

export default paths;
