import React from 'react';
import Social from "../../template/section-components/social";
import {Link, useLocation} from "react-router-dom";
import Copyright from "../../template/global-components/copyright";

const Footer = () => {
    let publicUrl = process.env.PUBLIC_URL+'/'
    const location = useLocation();

    if (location?.pathname?.includes('/cauta')) return null;

    return (
        <footer className="ltn__footer-area">
            {/*<div className="footer-top-area  section-bg-2 plr--5">*/}
            {/*    <div className="container-fluid">*/}
            {/*        <div className="row">*/}
            {/*            <div className="col-xl-3 col-md-6 col-sm-6 col-12">*/}
            {/*                <div className="footer-widget footer-about-widget">*/}
            {/*                    <div className="footer-logo">*/}
            {/*                        <div className="site-logo">*/}
            {/*                            <img src={publicUrl+"assets/img/logo-2.png"} alt="Logo" />*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                    <p>Portalul imobilelelor din Romania</p>*/}
            {/*                    <div className="footer-address">*/}
            {/*                        <ul>*/}
            {/*                            <li>*/}
            {/*                                <div className="footer-address-icon">*/}
            {/*                                    <i className="icon-mail" />*/}
            {/*                                </div>*/}
            {/*                                <div className="footer-address-info">*/}
            {/*                                    <p><a href="mailto:example@example.com">contact@caseplex.ro</a></p>*/}
            {/*                                </div>*/}
            {/*                            </li>*/}
            {/*                        </ul>*/}
            {/*                    </div>*/}
            {/*                    <div className="ltn__social-media mt-20">*/}
            {/*                        <Social />*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="col-xl-2 col-md-6 col-sm-6 col-12">*/}
            {/*                <div className="footer-widget footer-menu-widget clearfix">*/}
            {/*                    <h4 className="footer-title">Anunturi</h4>*/}
            {/*                    <div className="footer-menu go-top">*/}
            {/*                        <ul>*/}
            {/*                            <li><Link to="/about">About</Link></li>*/}
            {/*                            <li><Link to="/blog-grid">Blog</Link></li>*/}
            {/*                            <li><Link to="/shop">All Products</Link></li>*/}
            {/*                            <li><Link to="/contact">Contact</Link></li>*/}
            {/*                            <li><Link to="/faq">FAQ</Link></li>*/}
            {/*                            <li><Link to="/contact">Contact us</Link></li>*/}
            {/*                        </ul>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="col-xl-2 col-md-6 col-sm-6 col-12">*/}
            {/*                <div className="footer-widget footer-menu-widget clearfix">*/}
            {/*                    <h4 className="footer-title">Services</h4>*/}
            {/*                    <div className="footer-menu go-top">*/}
            {/*                        <ul>*/}
            {/*                            <li><Link to="/cart">Cart</Link></li>*/}
            {/*                            <li><Link to="/wishlist">Wish List</Link></li>*/}
            {/*                            <li><Link to="/login">Login</Link></li>*/}
            {/*                            <li><Link to="/checkout">Checkout</Link></li>*/}
            {/*                            <li><Link to="/about">Terms &amp; Conditions</Link></li>*/}
            {/*                            <li><Link to="/shop">Promotional Offers</Link></li>*/}
            {/*                        </ul>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="col-xl-2 col-md-6 col-sm-6 col-12">*/}
            {/*                <div className="footer-widget footer-menu-widget clearfix">*/}
            {/*                    <h4 className="footer-title">Customer Care</h4>*/}
            {/*                    <div className="footer-menu go-top">*/}
            {/*                        <ul>*/}
            {/*                            <li><Link to="/login">Login</Link></li>*/}
            {/*                            <li><Link to="/my-account">My account</Link></li>*/}
            {/*                            <li><Link to="/wishlist">Wish List</Link></li>*/}
            {/*                            <li><Link to="/add-listing">Add listing</Link></li>*/}
            {/*                            <li><Link to="/faq">FAQ</Link></li>*/}
            {/*                            <li><Link to="/contact">Contact us</Link></li>*/}
            {/*                        </ul>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="col-xl-3 col-md-6 col-sm-12 col-12">*/}
            {/*                <div className="footer-widget footer-newsletter-widget">*/}
            {/*                    <h4 className="footer-title">Newsletter</h4>*/}
            {/*                    <p>Subscribe to our weekly Newsletter and receive updates via email.</p>*/}
            {/*                    <div className="footer-newsletter">*/}
            {/*                        <form action="#">*/}
            {/*                            <input type="email" name="email" placeholder="Email*" />*/}
            {/*                            <div className="btn-wrapper">*/}
            {/*                                <button className="theme-btn-1 btn" type="submit"><i className="fas fa-location-arrow" /></button>*/}
            {/*                            </div>*/}
            {/*                        </form>*/}
            {/*                    </div>*/}
            {/*                    <h5 className="mt-30">We Accept</h5>*/}
            {/*                    <img src={publicUrl+"assets/img/icons/payment-4.png"} alt="Payment Image" />*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <Copyright />
        </footer>
    )
};

export default Footer;