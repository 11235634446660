import React, {useEffect, useState} from 'react';
import {SEARCH_DISPLAY} from "../../../types/search";
import httpService from "../../../services/httpService";
import ScrollToTop from "react-scroll-to-top";
import InfiniteScroll from "react-infinite-scroll-component";
import {Box, CircularProgress} from "@mui/material";
import {CheckCircleRounded} from "@mui/icons-material";
import Filters from "../../search/Filters";
import ListingsContainer from "../../listing/ListingsContainer";

const IntelligentSearchResults = ({ session }) => {
    const [results, setResults] = useState([]);
    const [searchDisplay, setSearchDisplay] = useState(SEARCH_DISPLAY.DEFAULT);

    // Pagination
    const [totalResults, setTotalResults] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [offset, setOffset] = useState(0);
    const limit = 36;

    /**
     * Handle change search display
     * @param option
     * @returns {*}
     */
    const handleChangeSearchDisplay = (option) => setSearchDisplay(option);

    const fetchNextData = async () => {
        const res = await httpService.get('/search/ai/results', {params: {...session, offset, limit}});
        const {results, hasMore, totalResults} = res.data;
        setOffset(prevOffset => prevOffset + limit);
        setResults(prevResults => {
            return prevResults.concat(results);
        });
        setTotalResults(totalResults);
        setHasMore(hasMore);
    };

    useEffect(() => {
        fetchNextData().then();
    }, []);

    return (
        <>
            <ScrollToTop smooth />
            <InfiniteScroll
                dataLength={results.length}
                next={fetchNextData}
                hasMore={hasMore}
                loader={
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        sx={{width: '100%', margin: '30px 0'}}
                    >
                        <p>Se incarca mai multe anunturi</p>
                        <CircularProgress size={80}/>
                    </Box>
                }
                endMessage={
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        sx={{width: '100%', margin: '30px 0'}}
                    >
                        { results.length === 0 && 'Nu exista resultate.' }
                        { results.length !== 0 && (
                            <>
                                <CheckCircleRounded fontSize="large" sx={{color: 'primary.main'}}/>
                                Acestea au fost toate rezultatele.
                            </>
                        ) }
                    </Box>
                }
            >
                <div className="ltn__product-area ltn__product-gutter mb-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Filters
                                    totalResults={totalResults.toLocaleString('ro-RO')}
                                    searchDisplay={searchDisplay}
                                    handleChangeSearchDisplay={handleChangeSearchDisplay}
                                    showFilterButton={false}
                                />
                                <div className="tab-content">
                                    <ListingsContainer display={searchDisplay} listings={results}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </InfiniteScroll>
        </>
    );

};

export default IntelligentSearchResults;