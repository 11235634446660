import React from 'react';

const Characteristics = ({ listing }) => {

    const isApartment = listing.category === 'apartamente' || listing.category === 'case-vile' ;

    const conditionalAdd = (condition, obj) => condition ? obj : null;

    const Characteristics = [
        conditionalAdd(isApartment, {icon: 'flaticon-double-bed', label: 'Camere', value: listing.rooms}),
        conditionalAdd(isApartment, {icon: 'flaticon-bathtub', label: 'Bai', value: listing.baths}),
        conditionalAdd(isApartment, {icon: 'flaticon-building', label: 'Clasa energetica', value: listing.energyClass || 'Fara'}),
        conditionalAdd(isApartment, {icon: 'flaticon-apartment', label: 'Etaj', value: listing.floor}),
    ].map(item => !item ? null : (
            <li key={item.label}>
                <div className="property-detail-feature-list-item">
                    <i className={item.icon}/>
                    <div>
                        <h6>{item.label}</h6>
                        <small>{item.value}</small>
                    </div>
                </div>
            </li>
        )
    );

    return (
        <>
            <h4 className="title-2">Caracteristice</h4>
            <div className="property-detail-feature-list clearfix mb-45">
                <ul>
                    { Characteristics }
                </ul>
            </div>
        </>
    );
};

export default Characteristics;