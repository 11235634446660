import React, {useEffect, useRef, useState} from 'react';
import {TYPE} from '../../enums/search';
import {counties, defaults} from '../../services/regionsService';
import {searchTypes, rooms, baths, comfort, kitchens, priceMin, priceMax} from '../../dropdowns/search';
import Form from '../common/Form';
import Select from '../common/Select';
import FormItem from '../common/FormItem';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Autocomplete,
    Button,
    TextField,
    Typography
} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {ExpandMore, Search} from '@mui/icons-material';
import {useHistory} from 'react-router-dom';
import pathService from '../../services/pathService';
import {setSearch} from '../../redux/actions/dataActions';
import {useDispatch, useSelector} from 'react-redux';

import SERVICES from '../../enums/services';
import httpService from "../../services/httpService";
import imageService from "../../services/imageService";
import {setOpenIS} from "../../redux/actions/intelligentSearchActions";
import useAnalyticsEventTracker from "../../hooks/analytics";
import ReactGA from "react-ga4";
import useGA from "../../hooks/analytics";


const useStyles = makeStyles(() => ({
    autocompleteInput: {
        textTransform: 'capitalize'
    }
}))

const TRACKING_ID = 'G-F0116S7C0T';

const SearchForm = () => {

    const history = useHistory();
    const dispatch = useDispatch();
    const styles = useStyles();
    const images = imageService.components.Slider;
    const ga = useGA('Search')

    const searchData = useSelector((state) => state.data?.search);

    const dropdowns = {
        category: searchTypes,
        counties: counties,
        rooms: rooms,
        baths: baths,
        kitchens: kitchens,
        comfort: comfort,
        priceMin: priceMin,
        priceMax: priceMax,
    }

    const [currentSearch, setCurrentSearch] = useState({
        service: SERVICES.SALE,
        category: TYPE.APARTMENT,
        county: null,
        city: null,
        rooms: null,
        baths: null,
        kitchens: null,
        comfort: null,
        priceMax: null,
        priceMin: null,
        ...(searchData ?? {})
    });

    const [citiesDropdown, setCitiesDropdown] = useState(async () => {
        if (searchData?.county) {
            await httpService
                .get(`/data/cities/${searchData.county}`)
                .then(response => {
                    setCitiesDropdown(['toate', ...response.data.results])
                });
        } else {
            return ['toate'];
        }
    });

    const isServiceActive = (option) => option === currentSearch.service ? 'active show' : null;

    /**
     * Handle change
     * @param event
     */
    const handleChange = (event) => {
        const {name, value} = event.target;
        setCurrentSearch({
            ...currentSearch,
            [name]: value,
        });
    }

    const initialRender = useRef(true);

    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false;
        } else {
            if (currentSearch.county === 'toate') {
                setCitiesDropdown(['toate']);
                setSearch(prevState => ({...prevState, city: 'toate'}));
            } else {
                httpService
                    .get(`/data/cities/${currentSearch.county}`)
                    .then(response => {
                        setCitiesDropdown(['toate', ...response.data.results])
                    });
            }


            setCurrentSearch(prevState => ({
                ...prevState,
                city: null,
            }));
        }
    }, [currentSearch.county])

    /**
     * Handle submit
     */
    const handleSubmit = () => {
        ga.event(`${currentSearch.service} - ${currentSearch.category} - ${currentSearch.county} - ${currentSearch.city}`)
        dispatch(setSearch(currentSearch));
        history.push(pathService.build.search(currentSearch), {search: currentSearch});
    };

    return (
        <div className="ltn__slider-area ltn__slider-4">
            <div
                className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-4 bg-image bg-overlay-theme-black-60"
                style={{backgroundImage: `url(${images.home})`}}
            >
                    <div className="ltn__slide-item-inner text-left" xyz="fade small-3 delay easy-in-out">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 align-self-center">
                                    <div className="slide-item-car-dealer-form">
                                        <div className="ltn__car-dealer-form-tab">
                                            <div className="ltn__tab-menu  text-uppercase">
                                                <div className="nav">
                                                    <a
                                                        style={{cursor: 'pointer'}}
                                                        className={isServiceActive(SERVICES.SALE)}
                                                        onClick={() => handleChange({
                                                            target: {
                                                                name: "service",
                                                                value: SERVICES.SALE
                                                            }
                                                        })}
                                                    >
                                                        <i className="fas fa-home"/>
                                                        De vanzare
                                                    </a>
                                                    <a
                                                        style={{cursor: 'pointer'}}
                                                        className={isServiceActive(SERVICES.RENT)}
                                                        onClick={() => handleChange({
                                                            target: {
                                                                name: "service",
                                                                value: SERVICES.RENT
                                                            }
                                                        })}
                                                    >
                                                        <i className="fas fa-home"/>
                                                        De inchiriat
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="tab-content">
                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    className="mt-3 mb-3"
                                                    onClick={() => dispatch(setOpenIS(true))}
                                                    disableElevation
                                                    startIcon={<Search/>}
                                                    size="large"
                                                >
                                                    apasa aici pentru cautare inteligenta
                                                </Button>
                                                <Form
                                                    handleSubmit={handleSubmit}
                                                    submitButtonText="Cauta"
                                                >
                                                    <FormItem
                                                        sizeLg={4}
                                                        sizeMd={6}
                                                    >
                                                        <Select
                                                            label="Tip"
                                                            name="category"
                                                            value={currentSearch.category}
                                                            options={dropdowns.category}
                                                            onChange={handleChange}
                                                        />
                                                    </FormItem>
                                                    <FormItem
                                                        sizeLg={4}
                                                        sizeMd={6}
                                                    >
                                                        <Autocomplete
                                                            disableClearable
                                                            className={styles.autocompleteInput}
                                                            value={currentSearch.county}
                                                            options={['toate', ...dropdowns.counties]}
                                                            onChange={(event, newValue) => {
                                                                handleChange({
                                                                    target: {
                                                                        name: 'county',
                                                                        value: newValue
                                                                    }
                                                                });
                                                            }}
                                                            renderInput={(params) => <TextField {...params}
                                                                                                className={styles.autocompleteInput}
                                                                                                label="Judet"
                                                                                                variant="filled"/>}
                                                        />
                                                    </FormItem>
                                                    <FormItem
                                                        sizeLg={4}
                                                        sizeMd={6}
                                                    >
                                                        <Autocomplete
                                                            disableClearable
                                                            value={currentSearch.city}
                                                            options={citiesDropdown || []}
                                                            onChange={(event, newValue) => {
                                                                handleChange({target: {name: 'city', value: newValue}});
                                                            }}
                                                            renderInput={(params) => <TextField {...params}
                                                                                                className={styles.autocompleteInput}
                                                                                                label={currentSearch.county === "bucuresti" ? 'Localitate/Sector' : 'Localitate'}
                                                                                                variant="filled"/>}
                                                        />
                                                    </FormItem>
                                                    <FormItem
                                                        sizeLg={12}
                                                        sizeMd={12}
                                                    >
                                                        <Accordion>
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMore/>}
                                                            >
                                                                <Typography>Mai multe optiuni</Typography>
                                                            </AccordionSummary>
                                                            <AccordionDetails className="row">
                                                                <FormItem
                                                                    sizeLg={6}
                                                                    sizeMd={6}
                                                                    sizeSm={12}
                                                                >
                                                                    <Select
                                                                        label="Pret minim"
                                                                        name="priceMin"
                                                                        value={currentSearch.priceMin}
                                                                        options={dropdowns.priceMin}
                                                                        onChange={handleChange}
                                                                    />
                                                                </FormItem>
                                                                <FormItem
                                                                    sizeLg={6}
                                                                    sizeMd={6}
                                                                    sizeSm={12}
                                                                >
                                                                    <Select
                                                                        label="Pret maxim"
                                                                        name="priceMax"
                                                                        value={currentSearch.priceMax}
                                                                        options={dropdowns.priceMax}
                                                                        onChange={handleChange}
                                                                    />
                                                                </FormItem>
                                                                <FormItem
                                                                    sizeLg={3}
                                                                    sizeMd={6}
                                                                    sizeSm={12}
                                                                >
                                                                    <Select
                                                                        label="Camere"
                                                                        name="rooms"
                                                                        value={currentSearch.rooms}
                                                                        options={dropdowns.rooms}
                                                                        onChange={handleChange}
                                                                    />
                                                                </FormItem>
                                                                <FormItem
                                                                    sizeLg={3}
                                                                    sizeMd={6}
                                                                    sizeSm={12}
                                                                >
                                                                    <Select
                                                                        label="Bai"
                                                                        name="baths"
                                                                        value={currentSearch.baths}
                                                                        options={dropdowns.baths}
                                                                        onChange={handleChange}
                                                                    />
                                                                </FormItem>
                                                                <FormItem
                                                                    sizeLg={3}
                                                                    sizeMd={6}
                                                                    sizeSm={12}
                                                                >
                                                                    <Select
                                                                        label="Bucatarii"
                                                                        name="kitchens"
                                                                        value={currentSearch.kitchens}
                                                                        options={dropdowns.kitchens}
                                                                        onChange={handleChange}
                                                                    />
                                                                </FormItem>
                                                                <FormItem
                                                                    sizeLg={3}
                                                                    sizeMd={6}
                                                                    sizeSm={12}
                                                                >
                                                                    <Select
                                                                        label="Comfort"
                                                                        name="comfort"
                                                                        value={currentSearch.comfort}
                                                                        options={dropdowns.comfort}
                                                                        onChange={handleChange}
                                                                    />
                                                                </FormItem>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </FormItem>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    )
}

export default SearchForm;
