import React from 'react';
import Login from '../components/containers/LoginPage/Login';

const LoginPage = () => {
  return (
    <>
      <Login />
    </>
  )
}

export default LoginPage;

