import React, {useEffect} from 'react';
import {AppBar, Button, Dialog, IconButton, Slide, Toolbar, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {Link, useHistory, useLocation} from "react-router-dom";
import ListingDetailsModal from "./ListingDetailsModal";
import {useDispatch, useSelector} from "react-redux";
import {setCloseListingView, setListingView, setOpenListingView} from "../../redux/actions/listViewActions";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import httpService from "../../services/httpService";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
});

const ListingViewModal = () => {
    const history = useHistory();
    const location = useLocation();
    const { search } = location;

    const dispatch = useDispatch();
    const {open, listing} = useSelector((state) => state.listingView);

    useEffect(() => {
        if (!!search) {
            const query = new URLSearchParams(search);
            const id = query.get('id');
            if (id) {
                httpService
                    .get(`/listing/${id}`)
                    .then(({data}) => {
                        dispatch(setListingView(data.listing));
                        dispatch(setOpenListingView());
                    });
            }
        }
    }, [])

    const handleClose = () => {
        history.push({
            ...location,
            search: null,
        })

        dispatch(setCloseListingView())
    }

    return (
        <>
            {
                listing && (
                    <Dialog
                        fullScreen
                        maxWidth="xlg"
                        open={open}
                        TransitionComponent={Transition}
                        sx={{zIndex: 999}}
                        onClose={() => handleClose()}
                    >
                        <AppBar color="secondary" position="sticky">
                            <Toolbar>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={() => handleClose()}
                                    aria-label="close"
                                >
                                    <ArrowBackIcon/>
                                    <Typography variant="h6" component="div">
                                        Inapoi
                                    </Typography>
                                </IconButton>
                                <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                                </Typography>
                            </Toolbar>
                        </AppBar>
                        <ListingDetailsModal listing={listing}/>
                    </Dialog>
                )
            }
        </>
    )
        ;
};

export default ListingViewModal;