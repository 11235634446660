import React, {useEffect, useState} from 'react';
import {SEARCH_DISPLAY} from '../../types/search';
import Form from "../common/Form";
import {
    Button,
    Dialog, DialogActions,
    DialogContent, DialogContentText,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    TextField, Typography
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {setSearch, setTriggerSearchChange} from "../../redux/actions/dataActions";
import {baths, comfort, priceMax, priceMin, rooms, searchTypes} from "../../dropdowns/search";
import Select from "../common/Select";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {TYPE} from "../../enums/search";
import Sticky from 'react-stickynode';
import {MobileView, BrowserView} from "react-device-detect";
import SearchIcon from '@mui/icons-material/Search';

const FilterInitialState = {
    category: TYPE.APARTMENT,
    priceMin: '',
    priceMax: '',
    rooms: '',
    baths: '',
    comfort: '',
}

const Filters = ({searchDisplay, handleChangeSearchDisplay, totalResults, showFilterButton = true}) => {

    const dispatch = useDispatch();

    const {search, searchUpdated} = useSelector((state) => state.data);
    const [searchState, setSearchState] = useState(search || FilterInitialState);
    const [openFilters, setOpenFilters] = useState(false);

    const [dropdowns, setDropdowns] = useState({
        category: searchTypes,
        priceMin: priceMin,
        priceMax: priceMax,
        comfort: comfort,
        baths: baths,
        rooms: rooms,
    })

    const sortingTypes = {
        default: 'default',
        priceAsc: '+price',
        priceDesc: '-price',
        dateAsc: '+date',
        dateDsc: '-date',
    }

    useEffect(() => {
        setSearchState(prevState => ({...prevState, ...search}));
    }, [searchUpdated]);

    const shouldShowActive = (value) => value === searchDisplay ? 'active show' : null;

    const handleClose = () => {
        setSearchState(search);
        setOpenFilters(false);
    }

    const handleSubmit = () => {
        dispatch(setSearch(searchState));
        dispatch(setTriggerSearchChange(true));
        setOpenFilters(false);
    }

    const handleReset = () => {
        setSearchState(FilterInitialState);
    }

    return (
        <>
            <div className="ltn__shop-options">
                <Grid container spacing={2}>
                    <Grid item xs={6} md={2} order={{xs: 1, md: 1, lg: 1}}>
                        <div className="ltn__grid-list-tab-menu ">
                            <div className="nav">
                                <a
                                    style={{cursor: 'pointer'}}
                                    className={shouldShowActive(SEARCH_DISPLAY.GRID)}
                                    onClick={() => handleChangeSearchDisplay(SEARCH_DISPLAY.GRID)}
                                >
                                    <i className="fas fa-th-large"/>
                                </a>
                                <a
                                    style={{cursor: 'pointer'}}
                                    className={shouldShowActive(SEARCH_DISPLAY.LIST)}
                                    onClick={() => handleChangeSearchDisplay(SEARCH_DISPLAY.LIST)}
                                >
                                    <i className="fas fa-list"/>
                                </a>
                                <a
                                    style={{cursor: 'pointer'}}
                                    className={shouldShowActive(SEARCH_DISPLAY.MAP)}
                                    onClick={() => handleChangeSearchDisplay(SEARCH_DISPLAY.MAP)}
                                >
                                    <i className="fas fa-map"/>
                                </a>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={7} order={{xs: 3, md: 2, lg: 2}} style={{zIndex: 9}}>
                        {
                            showFilterButton && (
                                <>
                                    <MobileView>
                                        <Sticky enabled top={10}>
                                            <Button
                                                variant="contained"
                                                fullWidth
                                                size="large"
                                                onClick={() => setOpenFilters(true)}
                                            >
                                                <SearchIcon/> Aplica Filtre
                                            </Button>
                                        </Sticky>
                                    </MobileView>
                                    <BrowserView>
                                        <Button
                                            variant="outlined"
                                            fullWidth
                                            size="large"
                                            onClick={() => setOpenFilters(true)}
                                        >
                                            <SearchIcon/> Aplica Filtre
                                        </Button>
                                    </BrowserView>
                                </>
                            )
                        }
                    </Grid>
                    <Grid item container xs={6} md={3} justifyContent="right" order={{xs: 2, md: 3, lg: 3}}>
                        <Typography className="mt-2" variant="subtitle2" component="p">{totalResults} anunturi</Typography>
                    </Grid>
                </Grid>
            </div>
            <Dialog
                fullWidth
                open={openFilters}
                onClose={() => setOpenFilters(false)}
                maxWidth="sm"
            >
                <DialogTitle>Filtre de cautare
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon/>
                    </IconButton></DialogTitle>
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={12}>
                            <Select
                                label="Categorie proprietati"
                                name="category"
                                value={searchState.category}
                                options={dropdowns.category}
                                handleChange={(value) => setSearchState(prev => ({...prev, category: value}))}
                            />
                        </Grid>
                        <Grid item container md={12} spacing={1}>
                            <Grid item xs={6} md={6}>
                                <Select
                                    fullWidth
                                    label="Pret minim"
                                    name="priceMin"
                                    value={searchState.priceMin}
                                    options={dropdowns.priceMin}
                                    handleChange={(value) => {
                                        if (!!value) {
                                            setDropdowns(prevState => ({
                                                ...prevState,
                                                priceMax: priceMax.filter(item => item.value >= value || item.value === '')
                                            }));
                                        } else {
                                            setDropdowns(prevState => ({...prevState, priceMax}))
                                        }
                                        if (!!searchState.priceMax) setSearchState(prev => ({...prev, priceMin: value <= searchState.priceMax ? value : null}));
                                        else setSearchState(prev => ({...prev, priceMin: value }));
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <Select
                                    label="Pret maxim"
                                    name="priceMax"
                                    value={searchState.priceMax}
                                    options={dropdowns.priceMax}
                                    handleChange={(value) => {
                                        if (!!value) {
                                            setDropdowns(prevState => ({
                                                ...prevState,
                                                priceMin: priceMax.filter(item => item.value <= value || item.value === '')
                                            }))
                                        } else {
                                            setDropdowns(prevState => ({...prevState, priceMin: priceMin}))
                                        }
                                        if (!!searchState.priceMin) setSearchState(prev => ({...prev, priceMax: value >= searchState.priceMin ? value : null}));
                                        else setSearchState(prev => ({...prev, priceMax: value }));
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container md={12} spacing={1}>
                            <Grid item xs={6} md={6}>
                                <Select
                                    label={"Nr. camere"}
                                    name="rooms"
                                    value={searchState.rooms}
                                    options={dropdowns.rooms}
                                    handleChange={(value) => setSearchState(prev => ({...prev, rooms: value}))}
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <Select
                                    label={"Nr. bai"}
                                    name="baths"
                                    value={searchState.baths}
                                    options={dropdowns.baths}
                                    handleChange={(value) => setSearchState(prev => ({...prev, baths: value}))}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {/*<Button onClick={handleClose} variant="outlined" startIcon={<CloseIcon/>}>Inchide</Button>*/}
                    <Button onClick={handleReset} variant="outlined" startIcon={<RestartAltIcon/>}>Reseteaza</Button>
                    <Button onClick={handleSubmit} variant="contained" startIcon={<CheckCircleIcon/>}
                            disableElevation>Aplica</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Filters;
