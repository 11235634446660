import React from 'react';
import {Link, useHistory} from 'react-router-dom';
import {Button, capitalize} from '@mui/material';
import {Favorite, Close} from '@mui/icons-material';
import Swal from 'sweetalert2';
import {v4 as uuid} from 'uuid';
import Services from "../../enums/services";
import {useDispatch, useSelector} from "react-redux";
import {
    addLocalUserSaved,
    addUserSaved,
    removeUserLocalSaved,
    removeUserSaved,
    setRemoveUserSaved, setUserSaved
} from "../../redux/actions/userActions";
import httpService from "../../services/httpService";
import {setListingView, setOpenListingView} from "../../redux/actions/listViewActions";
import {useLocation} from "react-router-dom";
import toast from "../../utils/toast";
import pathService from "../../services/pathService";

const GridListing = ({listing}) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const user = useSelector((state) => state.user);
    const isToSaved = user.saved?.includes(listing.id) || user.localSaved?.includes(listing.id);

    const isHouse = listing.category === 'apartamente' || listing.category === 'case-vile';
    const isLand = listing.category === 'teren';

    const descriptionList = [
        isHouse && {value: listing.rooms, label: listing.rooms > 1 ? 'camere' : 'camera'},
        isHouse && {value: listing.baths, label: 'bai'},
        isHouse && {value: listing.netArea, label: 'm2'},
        isHouse && {value: listing.builtYear, label: ''},

        isLand && {value: listing.netArea, label: 'm2'},
    ];

    const DescriptionItems = descriptionList.map(item => (
        <li key={uuid()}>
            <span>{item.value} </span>
            {item.label}
        </li>
    ))

    const addToSaved = () => {
        if (user.logged) {
            httpService
                .post(pathService.api.addSaved, {id: listing.id})
                .then(() => {
                    dispatch(addUserSaved(listing.id));
                })
        } else {
            dispatch(addLocalUserSaved(listing.id));
        }
        toast.success('', 'Anunt salvat la favorite')
    }

    const removeFromSaved = () => {
        if (user.logged) {
            httpService
                .delete(pathService.api.removeSaved, { params: { id: listing.id }})
                .then(() => {
                    dispatch(setUserSaved(user.saved.filter(us => us !== listing.id )));
                    dispatch(setRemoveUserSaved(listing.id));
                })
        } else {
            dispatch(setRemoveUserSaved(listing.id))
        }
    }

    const handleOpenListingView = () => {
        httpService
            .get(`/listing/${listing.id}`)
            .then(({data}) => {
                history.push({
                    ...location,
                    pathname: location?.pathname,
                    search: `id=${listing.id}`
                });
                dispatch(setListingView(data.listing));
                dispatch(setOpenListingView());
            });
    }
    return (
        <div className="col-lg-4 col-sm-6 col-12">
            <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center--- ">
                <div className="product-img">
                    <Link to="#" onClick={() => handleOpenListingView()}>
                        <img style={{width: 368, height: 276, objectFit: 'cover'}} src={
                            (listing.isThirdParty ? listing.thirdParty.mainImage : listing.mainImage)
                            || 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcROJGo_BDmE1BQXej-UemTXxZG6RkDsA95ZnA&usqp=CAU'
                        } alt="#"/>
                    </Link>
                    {/*<div className="real-estate-agent">*/}
                    {/*  <div className="agent-img">*/}
                    {/*    <Link to="/team-details"><img src={publicUrl + "assets/img/blog/author.jpg"} alt="#" /></Link>*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                </div>
                <div className="product-info">
                    <div className="product-badge">
                        <ul>
                            <li className="sale-badg">{listing.service}</li>
                        </ul>
                    </div>
                    <h2 className="product-title go-top" style={{height: 90, overflow: 'hidden'}}>
                        <Link to="#" onClick={() => handleOpenListingView()}>
                            {listing.title}
                        </Link>
                    </h2>
                    <div className="product-img-location">
                        <ul>
                            <li className="go-top">
                                <Link to="#"><i
                                    className="flaticon-pin"/> {capitalize(listing.area ? `${listing.area}, ` : '')} {capitalize(listing.county || '')}, {capitalize(listing.city || '')}
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
                        {DescriptionItems}
                    </ul>
                    <div className="product-hover-action">

                    </div>
                </div>
                <div className="product-info-bottom">
                    <div className="product-price">
                        <span>PRET: {listing.price.toLocaleString('ro-RO')}
                            <label>EURO{listing.service === Services.RENT && ' / luna'}</label></span>
                    </div>
                    {
                        isToSaved
                            ? (<Button variant="text" onClick={() => removeFromSaved()}
                                       startIcon={<Close/>}>Sterge</Button>
                            )
                            : (<Button variant="text" onClick={() => addToSaved()}
                                       startIcon={<Favorite/>}>Salveaza</Button>)
                    }
                </div>
            </div>
        </div>
    );
}

export default GridListing;
