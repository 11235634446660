import {ADD_USER_SAVED, SET_REDIRECT, SET_SEARCH, SET_TRIGGER_SEARCH_CHANGE} from '../types';
import SERVICES from "../../enums/services";
import {TYPE} from "../../enums/search";

const initialState = {
  searchUpdated: false,
  search: {
    service: SERVICES.SALE,
    category: TYPE.APARTMENT,
    county: '',
    city: null,
    rooms: null,
    baths: null,
    kitchens: null,
    comfort: null,
    priceMax: '',
    priceMin: '',
  },
  redirect: null,
  saved: [],
};
/**
 * data reducer
 * @param state
 * @param action
 * @return {{search: {}}}
 */
export default function(state = initialState, action) {
  switch (action.type) {
    case SET_SEARCH:
      return {
        ...state,
        search: {
          ...state.search,
          ...action.payload,
        },
      };
    case SET_TRIGGER_SEARCH_CHANGE:
      return {
        ...state,
        searchUpdated: action.payload,
      }
    case SET_REDIRECT:
      return {
        ...state,
        redirect: action.payload,
      }
    default:
      return state;
  }
}
