import React, {useEffect} from 'react';
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import KeyIcon from '@mui/icons-material/Key';
import Typography from "@mui/material/Typography";
import {ValidationForm} from "../../common/ValidationForm";
import InputText from "../../common/InputText";
import LoadingButton from "@mui/lab/LoadingButton";
import Container from "@mui/material/Container";
import httpService from "../../../services/httpService";
import CreateValidator from "../../../utils/validator";
import validationSchema from "../../../validation/activation";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import pathService from "../../../services/pathService";
import {loginUser} from "../../../redux/actions/userActions";
import toast from "../../../utils/toast";

const Activation = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector((state) => state.user);

    const {search} = useLocation();


    useEffect(() => {
        const query = new URLSearchParams(search);
        const token = query.get('token');
        if (token) {
            httpService
                .post('activate-account', {token, email: user.email })
                .then(({ data }) => {
                    toast.success('', 'Contul a fost activat cu succes.')
                    dispatch(loginUser(data, history));
                })
                .catch(error => {
                    if (error.response?.data?.code === 'activation-token-invalid') {
                        toast.error('URL de activare este invalid sau expirat.')
                    }
                })
        }
    }, [])

    useEffect(() => {
        if (!user.logged) return history.push(pathService.pages.login);
        if (user.active) return history.push(pathService.pages.home);
    }, [user])

    const translations = {
        title: 'Activeaza contul',
        description: 'Un email cu codul de activare a fost trimis',
        labels: {
            pin: 'Introdu codul de activare',
            submit: 'Activeaza'
        }
    }

    const handleSubmit = (data, actions) => {
        httpService
            .post('activate-account', {...data, email: user.email })
            .then(({ data }) => {
                toast.success('', 'Contul a fost activat cu succes.')
                dispatch(loginUser(data, history));
            })
            .catch(error => {
                if (error.response?.data?.formik) {
                    actions.setErrors(error.response.data?.formik);
                    actions.setSubmitting(false);
                }
            })
    }


    const validator = CreateValidator(validationSchema, handleSubmit);

    return (
        <Container component="main" maxWidth="md">
            <CssBaseline/>
            <Box
                sx={{
                    margin: '50px 0',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                    <KeyIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    {translations.title}
                </Typography>
                <Typography component="p" variant="p">
                    {translations.description}
                </Typography>

                <ValidationForm validator={validator}>
                    <InputText
                        disabled={validator.isSubmitting}
                        startAdornment={ 'COD -' }
                        name={validationSchema.fieldNames.pin}
                        label={translations.labels.pin}
                        helperText={'Verifica email-ul cu codul de activare'}
                    />
                    <LoadingButton
                        disabled={!validator.dirty || validator.isSubmitting}
                        loading={ validator.isSubmitting }
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{mt: 3, mb: 2}}
                    >
                        {translations.labels.submit}
                    </LoadingButton>
                    <Typography component="p" variant="caption" style={{ color: 'red', textAlign: 'center' }}>Daca nu ai primit emailul verifica folderul SPAM</Typography>
                </ValidationForm>
            </Box>
        </Container>
    );
};

export default Activation;