import React, {useEffect, useState} from 'react';
import IntelligentSearchResults from "../../components/containers/IntelligentSearchPage/IntelligentSearchResults";
import {useHistory, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";

const IntelligentSearchResultsPage = ({ session }) => {
    const location = useLocation();
    const [currentSession , setCurrentSession] = useState(null);
    const {sessions} = useSelector(state => state.intelligentSearch);
    useEffect(() => {
        const { state } = location;
        if (state?.session) setCurrentSession(state.session);
        else if (sessions.length) setCurrentSession(sessions[sessions.length -1 ]);
    },[location]);

    return (
        <>
            { currentSession && (
                <IntelligentSearchResults
                    session={ currentSession }
                />
            )}
        </>
    );
};

export default IntelligentSearchResultsPage;