import React from 'react';
import {Redirect, Route as ReactRouter} from "react-router-dom";
import {v4 as uuid} from "uuid";
import {useSelector} from "react-redux";
import pathService from "../../services/pathService";
import toast from "../../utils/toast";

const ProtectedRoute = ({ path, exact, requireNonAuth, Component}) => {
    const user = useSelector(state=>state.user);

    if (requireNonAuth && user.logged) {
        return <Redirect to={pathService.pages.home}/>
    }

    if (!requireNonAuth && !user.logged) {
        toast.warn('', 'Trebuie mai intai sa te autentifici')
        return <Redirect to={pathService.pages.login}/>
    }

    if (!requireNonAuth && !user.active && path !== pathService.pages.activation) {
        toast.warn('', 'Mai intai trebuie sa activezi contul.')
        return <Redirect to={pathService.pages.activation} />
    }

    return (
        <ReactRouter path={path} exact={exact} component={Component} key={uuid()}/>
    );
};

export default ProtectedRoute;