import {
  ADD_SESSION_INTELLIGENT_SEARCH,
  ADD_USER_SAVED,
  SET_OPEN_INTELLIGENT_SEARCH,
  SET_REDIRECT,
  SET_SEARCH,
  SET_TRIGGER_SEARCH_CHANGE
} from '../types';
import SERVICES from "../../enums/services";
import {TYPE} from "../../enums/search";

const initialState = {
  open: false,
  sessions: []
};
/**
 * data reducer
 * @param state
 * @param action
 * @return {{search: {}}}
 */
export default function(state = initialState, action) {
  switch (action.type) {
    case SET_OPEN_INTELLIGENT_SEARCH:
      return {
        ...state,
        open: action.payload
      };
    case ADD_SESSION_INTELLIGENT_SEARCH:
      if (state.sessions.length > 10) state.sessions.shift();
      return {
        ...state,
        sessions: [...state.sessions, action.payload]
      }
    default:
      return state;
  }
}
