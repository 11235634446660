import React, {useContext, useState} from 'react';
import {FilledInput, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel} from "@mui/material";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import {ValidatorContext} from "./ValidationForm";

const InputText = ({name, label, value, handleChange, password, helperText, startAdornment, useValidator = true, ...rest}) => {
    const validator = useContext(ValidatorContext);
    const [showPassword, setShowPassword] = useState(false);

    return (
        <FormControl
            variant="filled"
            fullWidth
            margin="normal"
            error={ useValidator ? (validator.touched[name] && Boolean(validator.errors[name])) : false}
        >
            <InputLabel htmlFor={name}>{label}</InputLabel>
            <FilledInput
                id={name}
                type={password ? (showPassword ? 'text' : 'password') : 'text'}
                name={name}
                value={ useValidator ? validator.values[name] : value}
                onChange={ useValidator ? validator.handleChange : handleChange}
                startAdornment={<InputAdornment position="start">{startAdornment}</InputAdornment>}
                endAdornment={
                    password &&
                    (<InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={(event) => event.preventDefault()}
                            edge="end"
                        >
                            {showPassword ? <VisibilityOff/> : <Visibility/>}
                        </IconButton>
                    </InputAdornment>)
                }
                {...rest}
            />
            { useValidator ? !!validator.touched[name] && validator.errors[name] && (<FormHelperText>{validator.errors[name]}</FormHelperText>) : null}
            {helperText && (<FormHelperText>{helperText}</FormHelperText>)}
        </FormControl>
    );
};

export default InputText;