import React from 'react';
import {Grid} from "@mui/material";
import Map from "./Map";

const MapViewContainer = ({ listings, search }) => {
    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Map listings={ listings } search={ search } />
            </Grid>
        </Grid>
    );
};

export default MapViewContainer;