export const formatNumber = (value) => parseInt(value.replace(/[^0-9]/g, ''))
export const formatCurrency = (value = '1', opt = {}) => {
    if(!!value) return formatNumber(value).toLocaleString('ro-RO', {maximumFractionDigits: 3})
    else return 0;
}

export const capitalizeWords = (value) => value.split(' ').map((word) => {
    return word[0].toUpperCase() + word.substring(1) + ' ';
});

export const shortFormatter = (num, digits= 2) => {
    const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup.slice().reverse().find(function(item) {
        return num >= item.value;
    });
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}
