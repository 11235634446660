const createPath = (path) => process.env.PUBLIC_URL + path;

export default {
  createPath,
  components: {
    Navbar: {
      logo: createPath('/assets/img/logo.png'),
      menu: createPath('/assets/img/banner/menu-banner-1.jpg'),
    },
    Features: {
      feature_1: createPath('/assets/img/icons/icon-img/21.png'),
      feature_2: createPath('assets/img/icons/icon-img/22.png'),
      feature_3: createPath('assets/img/icons/icon-img/23.png'),
    },
    Slider: {
      home: createPath('/assets/img/home/slider-bg.webp'),
    },
  },
  icons: {
    payment: createPath('/assets/img/icons/payment.png'),
  }
}
