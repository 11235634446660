import { TYPE } from '../enums/search';

export const searchTypes = [
  { label: 'apartamente', value: TYPE.APARTMENT },
  { label: 'case/vile', value: TYPE.HOUSE },
]

export const rooms = [
  { label: 'Toate', value: null },
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
]

export const baths = [
  { label: 'Toate', value: null },
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
]

export const comfort = [
  { label: 'Toate', value: null },
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
]

export const kitchens = [
  { label: 'Toate', value: null },
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
]

export const priceMin= [
  { label: 'Toate', value: '' },
  { label: '10.000 EURO', value: 10000 },
  { label: '20.000 EURO', value: 20000 },
  { label: '30.000 EURO', value: 30000 },
  { label: '40.000 EURO', value: 40000 },
  { label: '50.000 EURO', value: 50000 },
  { label: '60.000 EURO', value: 60000 },
  { label: '70.000 EURO', value: 70000 },
  { label: '80.000 EURO', value: 80000 },
  { label: '90.000 EURO', value: 90000 },
  { label: '100.000 EURO', value: 100000 },
  { label: '125.000 EURO', value: 125000 },
  { label: '150.000 EURO', value: 150000 },
  { label: '175.000 EURO', value: 175000 },
  { label: '200.000 EURO', value: 200000 },
  { label: '300.000 EURO', value: 300000 },
  { label: '400.000 EURO', value: 400000 },
  { label: '500.000 EURO', value: 500000 },
  { label: '1.000.000 EURO', value: 1000000 },
];

export const priceMax = [
  { label: 'Toate', value: '' },
  { label: '10.000 EURO', value: 10000 },
  { label: '20.000 EURO', value: 20000 },
  { label: '30.000 EURO', value: 30000 },
  { label: '40.000 EURO', value: 40000 },
  { label: '50.000 EURO', value: 50000 },
  { label: '60.000 EURO', value: 60000 },
  { label: '70.000 EURO', value: 70000 },
  { label: '80.000 EURO', value: 80000 },
  { label: '90.000 EURO', value: 90000 },
  { label: '100.000 EURO', value: 100000 },
  { label: '125.000 EURO', value: 125000 },
  { label: '150.000 EURO', value: 150000 },
  { label: '175.000 EURO', value: 175000 },
  { label: '200.000 EURO', value: 200000 },
  { label: '300.000 EURO', value: 300000 },
  { label: '400.000 EURO', value: 400000 },
  { label: '500.000 EURO', value: 500000 },
  { label: '1.000.000 EURO', value: 1000000 },
];
