import React from 'react';

const Details = ({listing}) => {

    const isApartment = listing.category === 'apartamente' || listing.category === 'case-vile';

    const conditionalAdd = (condition, obj) => condition ? obj : null;

    const mainDetails = [
        conditionalAdd(isApartment && !!listing.noOfStoreys, {label: 'Regim inaltime', value: listing.noOfStoreys}),
        conditionalAdd(isApartment && !!listing.partitions, {label: 'Compart.', value: listing.partitions}),
        conditionalAdd(isApartment && !!listing.structuralFrame, {
            label: 'Structura',
            value: listing.structuralFrame
        }),
        conditionalAdd(isApartment && !!listing.floor, {label: 'Etaj', value: listing.floor}),
        conditionalAdd(isApartment && !!listing.classification, {
            label: 'Clasificare',
            value: listing.classification
        }),
    ].map(item => item ? (<li key={item.label}><label>{item.label}:</label> <span>{item.value}</span></li>) : null);

    const secondaryDetails = [
        {label: 'ID Proprietate', value: listing.isThirdParty ? listing.thirdParty.id : listing.id},
        conditionalAdd(isApartment && !!listing.builtYear, {label: 'Anul constructiei', value: listing.builtYear}),
        conditionalAdd(isApartment && !!listing.comfort, {label: 'Comfort', value: listing.comfort}),
        conditionalAdd(isApartment && !!listing.builtArea, {
            label: 'Suprafata construita',
            value: `${listing.builtArea} mp`
        }),
        conditionalAdd(isApartment && !!listing.netArea, {label: 'Suprafata utila', value: `${listing.netArea} mp`}),
    ].map(item => item ? (<li key={item.label}><label>{item.label}:</label> <span>{item.value}</span></li>) : null);

    return (
        <div>
            <h4 className="title-2">Detaliile proprietatii</h4>
            <div className="property-detail-info-list section-bg-1 clearfix mb-60">
                <ul>
                    {mainDetails}
                </ul>
                <ul>
                    {secondaryDetails}
                </ul>
            </div>
        </div>
    );
};

export default Details;