import React, {useEffect} from 'react';
import {
    ClickAwayListener, Collapse,
    Drawer,
    List,
    ListItem,
    ListItemButton,
    ListItemText
} from "@mui/material";

import {useDispatch, useSelector} from "react-redux";
import {setOpenDrawerMenuMobile} from "../../../redux/actions/uiActions";

import pathService from "../../../services/pathService";
import { useHistory} from "react-router-dom";
import Services from "../../../enums/services";
import {setSearch, setTriggerSearchChange} from "../../../redux/actions/dataActions";
import {TYPE} from "../../../enums/search";

const MobileMenu = () => {

    const history = useHistory();
    const dispatch = useDispatch();
    const {drawer} = useSelector((state) => state.ui);

    const [collapsible, setCollapsible] = React.useState({sell: false, rent: false});

    return (
        <Drawer
            anchor="right"
            open={drawer.menuMobile}
            onClose={() => dispatch(setOpenDrawerMenuMobile(false))}
        >
            <List>
                <ListItem style={{WebkitBoxShadow: 'var(--ltn__box-shadow-4)'}}>
                    <ListItemButton
                        style={{margin: '8px 40px'}}
                        onClick={() => {
                            history.push(pathService.pages.home);
                            dispatch(setOpenDrawerMenuMobile(false));
                        }}>
                        <ListItemText sx={{textAlign: 'center'}}>
                            Acasa
                        </ListItemText>
                    </ListItemButton>
                </ListItem>
                <ClickAwayListener onClickAway={() => setCollapsible(prevState => ({...prevState, sell: false}))}>
                    <div>
                        <ListItem style={{WebkitBoxShadow: 'var(--ltn__box-shadow-4)'}}>
                            <ListItemButton style={{margin: '8px 40px'}} onClick={() => setCollapsible(prevState => ({
                                ...prevState,
                                sell: !prevState.sell
                            }))}>
                                <ListItemText sx={{textAlign: 'center'}}>Vanzare</ListItemText>
                            </ListItemButton>
                        </ListItem>
                        <Collapse in={collapsible.sell} timeout="auto">
                            <List component="div">
                                <ListItemButton
                                    style={{WebkitBoxShadow: 'var(--ltn__box-shadow-2)'}}
                                    onClick={() => {
                                        const data = {
                                            service: Services.SALE,
                                            category: TYPE.APARTMENT,
                                            county: 'toate',
                                            city: 'toate'
                                        }
                                        dispatch(setSearch(data))
                                        history.push(pathService.build.search(data));
                                        dispatch(setTriggerSearchChange(true));
                                        dispatch(setOpenDrawerMenuMobile(false));
                                    }}>
                                    <ListItemText sx={{textAlign: 'center'}}>Apartamente</ListItemText>
                                </ListItemButton>
                                <ListItemButton
                                    style={{WebkitBoxShadow: 'var(--ltn__box-shadow-2)'}}
                                    onClick={() => {
                                        const data = {
                                            service: Services.SALE,
                                            category: TYPE.HOUSE,
                                            county: 'toate',
                                            city: 'toate'
                                        }
                                        dispatch(setSearch(data))
                                        history.push(pathService.build.search(data));
                                        dispatch(setTriggerSearchChange(true));
                                        dispatch(setOpenDrawerMenuMobile(false));
                                    }}
                                >
                                    <ListItemText sx={{textAlign: 'center'}}>Case/Vile</ListItemText>
                                </ListItemButton>
                                <ListItemButton
                                    style={{WebkitBoxShadow: 'var(--ltn__box-shadow-2)'}}
                                    onClick={() => {
                                        const data = {
                                            service: Services.SALE,
                                            category: TYPE.LANDS,
                                            county: 'toate',
                                            city: 'toate'
                                        }
                                        dispatch(setSearch(data))
                                        history.push(pathService.build.search(data));
                                        dispatch(setTriggerSearchChange(true));
                                        dispatch(setOpenDrawerMenuMobile(false));
                                    }}
                                >
                                    <ListItemText sx={{textAlign: 'center'}}>Terenuri</ListItemText>
                                </ListItemButton>
                            </List>
                        </Collapse>
                    </div>
                </ClickAwayListener>
                <ClickAwayListener onClickAway={() => setCollapsible(prevState => ({...prevState, rent: false}))}>
                    <div>
                        <ListItem style={{WebkitBoxShadow: 'var(--ltn__box-shadow-4)'}}>
                            <ListItemButton style={{margin: '8px 40px'}} onClick={() => setCollapsible(prevState => ({
                                ...prevState,
                                rent: !prevState.rent
                            }))}>
                                <ListItemText sx={{textAlign: 'center'}}>Inchiriere</ListItemText>
                            </ListItemButton>
                        </ListItem>
                        <Collapse in={collapsible.rent} timeout="auto">
                            <List component="div">
                                <ListItemButton
                                    style={{WebkitBoxShadow: 'var(--ltn__box-shadow-2)'}}
                                    onClick={() => {
                                        const data = {
                                            service: Services.RENT,
                                            category: TYPE.APARTMENT,
                                            county: 'toate',
                                            city: 'toate'
                                        }
                                        dispatch(setSearch(data))
                                        history.push(pathService.build.search(data));
                                        dispatch(setTriggerSearchChange(true));
                                        dispatch(setOpenDrawerMenuMobile(false));
                                    }}
                                >
                                    <ListItemText sx={{textAlign: 'center'}}>Apartamente</ListItemText>
                                </ListItemButton>
                                <ListItemButton
                                    style={{WebkitBoxShadow: 'var(--ltn__box-shadow-2)'}}
                                    onClick={() => {
                                        const data = {
                                            service: Services.RENT,
                                            category: TYPE.HOUSE,
                                            county: 'toate',
                                            city: 'toate'
                                        }
                                        dispatch(setSearch(data))
                                        history.push(pathService.build.search(data));
                                        dispatch(setTriggerSearchChange(true));
                                        dispatch(setOpenDrawerMenuMobile(false));
                                    }}
                                >
                                    <ListItemText sx={{textAlign: 'center'}}>Case/Vile</ListItemText>
                                </ListItemButton>
                            </List>
                        </Collapse>
                    </div>
                </ClickAwayListener>
                <ListItem style={{WebkitBoxShadow: 'var(--ltn__box-shadow-4)'}}>
                    <ListItemButton
                        style={{margin: '8px 40px'}}
                        onClick={() => {
                            history.push(pathService.pages.agents);
                            dispatch(setOpenDrawerMenuMobile(false));
                        }}
                    >
                        <ListItemText sx={{textAlign: 'center'}}>Agentii</ListItemText>
                    </ListItemButton>
                </ListItem>
                <ListItem style={{WebkitBoxShadow: 'var(--ltn__box-shadow-4)'}}>
                    <ListItemButton
                        style={{margin: '8px 40px'}}
                        onClick={() => {
                            history.push(pathService.pages.developers);
                            dispatch(setOpenDrawerMenuMobile(false));
                        }}
                    >
                        <ListItemText sx={{textAlign: 'center'}}>Devoltatori</ListItemText>
                    </ListItemButton>
                </ListItem>
                <ListItem style={{WebkitBoxShadow: 'var(--ltn__box-shadow-4)'}}>
                    <ListItemButton
                        style={{margin: '8px 40px'}}
                        onClick={() => {
                            history.push(pathService.pages.contact);
                            dispatch(setOpenDrawerMenuMobile(false));
                        }}
                    >
                        <ListItemText sx={{textAlign: 'center'}}>Contact</ListItemText>
                    </ListItemButton>
                </ListItem>
            </List>
        </Drawer>
    );
};

export default MobileMenu;