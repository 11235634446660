import React, {useContext} from 'react';
import {FormControl, FormHelperText} from "@mui/material";
import {ValidatorContext} from "./ValidationForm";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const InputCheckbox = ({name, label, ...rest}) => {
    const validator = useContext(ValidatorContext);

    return (
        <FormControl
            error={!!validator.touched[name] && !!validator.errors[name]}
        >
            <FormControlLabel

                id={name}
                name={name}
                label={label}
                control={<Checkbox checked={!!validator.values[name] }
                />}
                onChange={(e) => {
                    e.target.value = e.target.checked;
                    validator.handleChange(e)
                }}
                {...rest}
            >
            </FormControlLabel>
            {!!validator.touched[name] && validator.errors[name] && (
                <FormHelperText>{validator.errors[name]}</FormHelperText>
            )}
        </FormControl>
    );
};

export default InputCheckbox;