import {ACCOUNT} from "../enums/account";
import * as yup from "yup";

export const initialValues = {
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    account: ACCOUNT.INDIVIDUAL,
    termsAndCond: false,
}

export const validationSchema = yup.object().shape({
    firstname: yup.string().required('Prenumele este obligatoriu'),
    lastname: yup.string().required('Numele este obligatoriu'),
    email: yup.string()
        .required('Adresa de email este obligatorie')
        .email('Email-ul nu este valid'),
    password: yup.string().required('Parola este obligatorie').min(6, 'Trebuie sa fie peste 6 charactere').max('100', 'Trebuie sa fie sub 100 charactere'),
    account: yup.string().required('Tip de cont este obligatoriu'),
    termsAndCond: yup.boolean().oneOf([true], 'Trebuie sa fii deacord cu termenii si conditiile'),
});

export const fieldNames = {
    firstname: 'firstname',
    lastname: 'lastname',
    email: 'email',
    password: 'password',
    account: 'account',
    termsAndCond: 'termsAndCond'
}

export default {initialValues, validationSchema, fieldNames};