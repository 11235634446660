import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    capitalize, Checkbox, FormControl, FormControlLabel, Grid, Input, InputAdornment, InputLabel, TextField, Typography
} from "@mui/material";
import {formatCurrency, formatNumber} from "../../../utils/format";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {Autocomplete} from "@mui/lab";
import {AccountCircle, Clear, Search} from "@mui/icons-material";

const BuiltYear = ({active, currentSearch, analytics, handleChange}) => {
    const [selectedAll, setSelectedAll] = useState(false);
    const [toggleAbove, setToggleAbove] = useState(false);
    const [years, setYears] = useState(new Set());
    const [search, setSearch] = useState('');
    const [filteredResults, setFilteredResults] = useState(analytics.builtYear);

    const handleCheckBoxChange = (e, value) => {
        const {checked} = e.target;
        if (checked) {
            setYears(previousState => new Set([...previousState, value]));
        } else {
            setYears(previousState => new Set([...previousState].filter(v => v !== value)));
        }
    }
    const toggleSelectAll = () => {
        if (selectedAll) {
            setSelectedAll(false);
            setYears(new Set([]));
        } else {
            setSelectedAll(true);
            setYears(new Set([...analytics.builtYear]));
        }
    }

    const handleToggleAbove = () => {
        if (toggleAbove) {
            setToggleAbove(false);
            setYears(new Set([]));
        } else {
            setToggleAbove(true);
            setYears(new Set([...analytics.builtYear.filter(year => formatNumber(year) >= 2000)]));
        }
    }

    useEffect(() => {
        handleChange({
            target: {
                name: 'builtYear',
                value: [...years]
            }
        })
    }, [years]);

    useEffect(() => {
        if (!!search && search.length > 0) {
            setFilteredResults(analytics.builtYear.filter(builtYear => builtYear.includes(search)))
        } else {
            setFilteredResults(analytics.builtYear)
        }
    }, [search]);

    useEffect(() => {
        setFilteredResults(analytics.builtYear);
    }, [analytics])

    if (!active) return null;
    return (
        <Grid
            container
            spacing={0}
            direction="row"
            alignItems="center"
            justifyContent="center"
            maxWidth="md"
        >
            <Grid item>
                <Typography sx={{mt: 1}} variant="h5" textAlign="center">An</Typography>
                <Typography sx={{mb: 1}} variant="subtitle2" textAlign="center">
                    Selectati anii pe care le doriti in bugetul de {formatCurrency(currentSearch.budget)} EURO
                </Typography>
            </Grid>
            <Grid container spacing={2} justifyContent="center">
                <Grid
                    item
                    container
                    spacing={0}
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    xs={12} md={12}

                >
                    <Grid item xs={6} md={6}>
                        <Button
                            variant="contained"
                            disableElevation
                            onClick={() => toggleSelectAll()}
                            startIcon={selectedAll ? <CheckBoxOutlineBlankIcon/> : <CheckBoxIcon/>}
                        >
                            {selectedAll ? 'Deselectati toti anii' : 'Selectati toti anii'}
                        </Button>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <Button
                            variant="contained"
                            disableElevation
                            onClick={() => handleToggleAbove()}
                            startIcon={toggleAbove ? <CheckBoxOutlineBlankIcon/> : <CheckBoxIcon/>}
                        >
                            {toggleAbove ? 'Deselectati peste 2000' : 'Selectati peste 2000'}
                        </Button>
                    </Grid>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle2">Numarul total de rezultate: {analytics.builtYear.length} ({years.size} selectate)</Typography>
                </Grid>
                <Grid
                    item
                    container
                    sx={{
                        m: 1,
                        border: 1,
                        borderRadius: '10px',
                        borderColor: 'primary.main',
                        height: 400,
                        overflow: "hidden",
                        overflowY: "scroll",
                    }}
                >
                    <Grid
                        item
                        container
                        alignItems="center"
                        justifyContent="center"
                        xs={12} md={12}>
                        <TextField
                            fullWidth
                            label="Cauta an"
                            sx={{ mb: 2, mr: 2 }}
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><Search /></InputAdornment>,
                                endAdornment: <InputAdornment position="end" component="a" style={{ cursor: 'pointer' }}
                                                              onClick={() => setSearch('')}
                                ><Clear/></InputAdornment>
                            }}
                        />
                    </Grid>
                    {filteredResults?.sort()?.reverse()?.map(item => (
                        <Grid item xs={4} md={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        sx={{color: 'primary.main'}}
                                        checked={years.has(item)}
                                        onChange={(e) => handleCheckBoxChange(e, item)}
                                    />
                                }
                                label={item}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default BuiltYear;