import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const SelectInput = ({ label, name, value, options, handleChange, ...otherProps }) => {
  return (
  <FormControl variant="filled" fullWidth>
    <InputLabel>{label}</InputLabel>
    <Select
      name={ name }
      value={ value }
      onChange={(event) => handleChange(event.target.value) }
      {...otherProps}
    >
      { options?.map(({ label, value }) => <MenuItem value={value} key={value}>{label}</MenuItem>) }
    </Select>
  </FormControl>
  )
}

export default SelectInput;
