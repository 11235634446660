import React from 'react';
import {Link} from 'react-router-dom';
import imageService from "../../services/imageService";

const Features = (props) => {
    const customClass = props.customClass ? props.customClass : '';
    const images = imageService.components.Features;

    return (
        <div className={customClass}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title-area ltn__section-title-2--- text-center">
                            <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Serviciile
                                noastre</h6>
                            <h1 className="section-title">Pentru cine oferim aceste servicii</h1>
                        </div>
                    </div>
                </div>
                <div className="row ltn__custom-gutter--- justify-content-center go-top">
                    <div className="col-lg-4 col-sm-6 col-12">
                        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                            <div className="ltn__feature-icon">
                                <img src={ images.feature_1 } alt="#"/>
                            </div>
                            <div className="ltn__feature-info">
                                <h3><Link to="/service-details">Cumparatori imobiliare</Link></h3>
                                <p>Pe platforma noastre se gasesc zeci de anunturi de vanzare din toata tara.</p>
                                {/*<Link className="ltn__service-btn" to="/service-details">Find A Home <i className="flaticon-right-arrow" /></Link>*/}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                        <div
                            className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1 active">
                            <div className="ltn__feature-icon">
                                <img src={ images.feature_2 } alt="#"/>
                            </div>
                            <div className="ltn__feature-info">
                                <h3><Link to="/service-details">Inchiriere imobiliare</Link></h3>
                                <p>Gasesti imobilul perfect de inchiriat din zona pe care ti-o doresti.</p>
                                {/*<Link className="ltn__service-btn" to="/service-details">Find A Home <i className="flaticon-right-arrow" /></Link>*/}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                            <div className="ltn__feature-icon">
                                <img src={ images.feature_3 } alt="#"/>
                            </div>
                            <div className="ltn__feature-info">
                                <h3><Link to="/service-details">Vanzare imobiliare</Link></h3>
                                <p>Dedicat persoanelor fizice sau agentilor pentru a lista anunturi gratuite.</p>
                                {/*<Link className="ltn__service-btn" to="/service-details">Find A Home <i className="flaticon-right-arrow" /></Link>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Features;