import React, {useEffect, useState} from 'react';
import GaugeChart from 'react-gauge-chart'
import httpService from "../../../../services/httpService";
import {capitalize, Typography} from "@mui/material";
import {capitalizeWords} from "../../../../utils/format";

const Analytics = ({listing}) => {

    const [analytics, setAnalytics] = useState(null);
    const [percent, setPercent] = useState(0.5);
    const [value, setValue] = useState('bun');

    const calculateResult = (analytics) => {
        const { average } = analytics;

        let percentage;
        if (listing.price / (average * 2) < 1) {
            percentage = listing.price / (average * 2);
        } else {
            percentage = 1;
        }

        setPercent(percentage);
        if (percentage > 0.4 && percentage < 0.6) setValue('excellent');
        if ((percentage < 0.5 && percentage < 0.3) || (percentage > 0.5 && percentage < 0.7)) setValue('bun');
        if (percentage < 0.3) setValue('ieftin');
        if (percentage > 0.7) setValue('scump');
    }

    useEffect(() => {
        httpService
            .get(`/listing/analytics/${listing._id}`)
            .then(({data}) => {
                const {hasResults, result} = data;
                if (hasResults) {
                    setAnalytics(result);
                    calculateResult(result);
                }
            })
    }, [listing]);

    if (!analytics) return null;

    return (
        <>
            <h4 className="title-2">Analiza de pret</h4>
            <div className="mb-45">
                <GaugeChart
                    id="gauge-chart5"
                    nrOfLevels={420}
                    arcsLength={[0.3, 0.5, 0.3]}
                    colors={['#F5CD19', '#5BE12C', '#EA4228']}
                    percent={percent}
                    arcPadding={0.05}
                    textColor="#000000"
                    formatTextValue={() => value}
                />
                <Typography variant="h4" component="h4" align="center">
                    Pretul imobilului { listing.price.toLocaleString('ro-RO') } EURO
                </Typography>
                <Typography variant="h5" component="h5">
                    Pretul mediu pentru un imobil in zona { capitalizeWords(listing.area) } din anul { listing.builtYear } cu { listing.rooms } camere  este de { analytics.average.toLocaleString('ro-RO') } EURO
                </Typography>
                <Typography variant="h6" component="h6">
                    Preturile din acesta categorie incep de la { analytics.min.toLocaleString('ro-RO') } EURO pana la { analytics.max.toLocaleString('ro-RO') } EURO
                </Typography>
            </div>
        </>
    );
};

export default Analytics;