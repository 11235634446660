import React from 'react';
import Meta from "./ListingDetails/ListingDetails/Meta";
import Description from "./ListingDetails/ListingDetails/Description";
import Characteristics from "./ListingDetails/ListingDetails/Characteristics";
import Details from "./ListingDetails/ListingDetails/Details";
import Utilities from "./ListingDetails/ListingDetails/Utilities";
import {Button} from "@mui/material";
import Slider from "./ListingDetails/ListingDetails/Slider";
import Services from "../../enums/services";
import Analytics from "./ListingDetails/ListingDetails/Analytics";
import AdListing from "../adsense/AdListing";
import AdSense from 'react-adsense';

const ListingDetailsModal = ({listing}) => {
    let sellerImage = '';
    if (listing.isThirdParty) {
        if (listing.thirdParty.source === 'Imobiliare.ro') sellerImage = 'https://www.imobiliare.ro/imagini/v8/logo-red-594x112.png';
        if (listing.thirdParty.source === 'Storia.ro') sellerImage = 'https://statics.storia.ro/fp_statics/images/logo/storia_color.png';
    }
    return (
        <div className="ltn__shop-details-area pb-10 pt-50">
            <div className="ltn__shop-details-area pb-10">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="ltn__shop-details-inner ltn__page-details-inner mb-60">
                                {/*<ListingSlider listing={listing}/>*/}
                                <Meta listing={listing}/>
                                <Slider listing={listing} />
                                <Description listing={listing}/>
                                <Characteristics listing={listing}/>
                                <Details listing={listing}/>
                                <Utilities listing={listing}/>
                                <Analytics listing={listing} />
                                {/*<div>*/}
                                {/*    <AdSense.Google*/}
                                {/*        client='ca-pub-8578604407061099'*/}
                                {/*        slot='4482395909'*/}
                                {/*    />*/}
                                {/*</div>*/}
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <aside className="sidebar ltn__shop-sidebar ltn__right-sidebar---">
                                {/* Author Widget */}
                                <div className="widget ltn__author-widget">
                                    <div className="ltn__author-widget-inner text-center">
                                        <img
                                            src={listing.isThirdParty ? sellerImage : listing.mainImage}
                                            alt="Image"/>
                                        <h4>Adaugat
                                            de {listing.isThirdParty ? listing.thirdParty.source : listing.seller.name}</h4>
                                        {listing.isThirdParty && (
                                            <>
                                                <small>Acest anunt este luat de pe o sursa terta.</small>
                                                <Button className="mt-3" href={listing.thirdParty.url} rel="nofollow"
                                                        target="_blank">Contacteaza proprietarul</Button>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListingDetailsModal;