import React from 'react';
import {Link, useHistory} from "react-router-dom";
import pathService from "../../../services/pathService";
import {useDispatch, useSelector} from "react-redux";
import {userLogout} from "../../../redux/actions/userActions";
import {Box, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import {setOpenDrawerUser, setOpenMobileMenu} from "../../../redux/actions/uiActions";
import {BrowserView, MobileView} from "react-device-detect";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import InterestsIcon from '@mui/icons-material/Interests';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import LogoutIcon from '@mui/icons-material/Logout';

const User = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const {drawer} = useSelector((state) => state.ui);
    const history = useHistory();

    const Item = ({path, text, Icon, onClick}) => (
        <ListItem
            style={{'-webkit-box-shadow': 'var(--ltn__box-shadow-4)'}}
            onClick={() => {
                if (onClick) return onClick();
                history.push(path);
                dispatch(setOpenDrawerUser(false));
            }}
        >
            <ListItemButton style={{margin: '8px 40px'}}>
                { Icon && <ListItemIcon>{Icon}</ListItemIcon> }
                <ListItemText sx={{textAlign: 'center'}} primary={text} />
            </ListItemButton>
        </ListItem>
    )

    const Content = () => {
        if (user.logged) {
            return (
                <Box>
                    <List>
                        <Item path={pathService.pages.user.account} Icon={<AccountCircleIcon/>} text="Contul meu"/>
                        <Item path={pathService.pages.user.saved} Icon={<InterestsIcon/>} text="Anunturi salvate"/>
                        <Item path={pathService.pages.user.addListing} Icon={<AddCircleIcon/>} text="Adauga anunt"/>
                        <Item onClick={() => dispatch(userLogout())} Icon={<LogoutIcon/>} text="Delogare"/>
                    </List>
                </Box>
            )
        } else {
            return (
                <Box>
                    <List>
                        <Item path={pathService.pages.login} text="Logare"/>
                        <Item path={pathService.pages.register} text="Inregistrare"/>
                    </List>
                </Box>
            )
        }
    }

    return (
        <>
            <BrowserView>
                <>
                    {
                        user.logged && (
                            <div className="ltn__drop-menu user-menu">
                                <ul>
                                    <li>
                                        <Link to="#">
                                            <i className="icon-user" style={{marginRight: 8}}/>
                                            {`${user.firstname} ${user.lastname}`}
                                        </Link>
                                        <ul className="go-top">
                                            <li><Link to={pathService.pages.user.account}>Contul meu</Link></li>
                                            <li><Link to={pathService.pages.user.saved}>Anunturi salvate</Link></li>
                                            <li><Link to={pathService.pages.user.addListing}>Adauga anunt</Link></li>
                                            <li><Link to="#" onClick={() => dispatch(userLogout())}>Delogare</Link></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        )}
                    {
                        !user.logged && (
                            <div className="ltn__drop-menu user-menu">
                                <ul>
                                    <li>
                                        <Link to="#">
                                            <i className="icon-user" style={{marginRight: 8}}/>
                                            Contul meu
                                        </Link>
                                        <ul className="go-top">
                                            <li><Link to={pathService.pages.login}>Logare</Link></li>
                                            <li><Link to={pathService.pages.register}>Inregistrare</Link></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        )
                    }
                </>
            </BrowserView>
            <MobileView>
                <div className="ltn__drop-menu user-menu">
                    <ul>
                        <li>
                            <Link to="#" onClick={() => dispatch(setOpenDrawerUser(true))}>
                                <i className="icon-user" style={{marginRight: 8}}/>
                                {user.logged ? `${user.firstname} ${user.lastname}` : 'Contul meu'}
                            </Link>
                        </li>
                    </ul>
                </div>
                <Drawer
                    anchor="right"
                    open={drawer.user}
                    onClose={() => dispatch(setOpenDrawerUser(false))}
                >
                    <Content/>
                </Drawer>
            </MobileView>
        </>
    );
};

export default User;