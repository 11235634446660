import React from 'react';
import {Link} from "react-router-dom";

const MenuTypes = {
    SINGLE: 'SINGLE', MULTIPLE: 'MULTIPLE', MEGA: 'MEGA'
}

const HeaderMenuDesktop = (props) => {

    const menus = [
        {
            type: MenuTypes.SINGLE, link: '/', label: 'Acasa'
        },
        {
            type: MenuTypes.MULTIPLE,
            link: '/',
            label: 'Vanzare',
            subMenu: [
                {link: '/', label: 'Apartamente'},
                {link: '/', label: 'Case'},
                {link: '/', label: 'Terenuri'},
            ]
        },
        {
            type: MenuTypes.MULTIPLE,
            link: '/',
            label: 'Inchiriere',
            subMenu: [
                {link: '/', label: 'Apartamente'},
                {link: '/', label: 'Case'},
            ]
        },
        // {
        //     type: MenuTypes.MEGA,
        //     link: '#',
        //     label: 'Anunturi',
        //     subMenu: [
        //         {
        //             link: '#',
        //             label: 'Vanzare Apartamente',
        //             subMenu: [
        //                 {link: '/', label: 'Apartamente in Bucuresti'},
        //                 {link: '/', label: 'Apartamente in Brasov'},
        //                 {link: '/', label: 'Apartamente in Cluj'},
        //                 {link: '/', label: 'Apartamente in Sibiu'},
        //                 {link: '/', label: 'Apartamente in Constanta'},
        //                 {link: '/', label: 'Apartamente in Iasi'},
        //                 {link: '/', label: 'Apartamente in Craiova'},
        //             ]
        //         },
        //         {
        //             link: '#',
        //             label: 'Inchiriere Apartamente',
        //             subMenu: [
        //                 {link: '/', label: 'Apartamente in Bucuresti'},
        //                 {link: '/', label: 'Apartamente in Brasov'},
        //                 {link: '/', label: 'Apartamente in Cluj'},
        //                 {link: '/', label: 'Apartamente in Sibiu'},
        //                 {link: '/', label: 'Apartamente in Constanta'},
        //                 {link: '/', label: 'Apartamente in Iasi'},
        //                 {link: '/', label: 'Apartamente in Craiova'},
        //             ]
        //         },
        //         {
        //             link: '#',
        //             label: 'Vanzare Case/Vile',
        //             subMenu: [
        //                 {link: '/', label: 'Apartamente in Bucuresti'},
        //                 {link: '/', label: 'Apartamente in Brasov'},
        //                 {link: '/', label: 'Apartamente in Cluj'},
        //                 {link: '/', label: 'Apartamente in Sibiu'},
        //                 {link: '/', label: 'Apartamente in Constanta'},
        //                 {link: '/', label: 'Apartamente in Iasi'},
        //                 {link: '/', label: 'Apartamente in Craiova'},
        //             ]
        //         },
        //     ]
        // },
        {
            type: MenuTypes.SINGLE, link: '/', label: 'Agentii'
        },
        {
            type: MenuTypes.SINGLE, link: '/', label: 'Dezvoltatori'
        },
        {
            type: MenuTypes.SINGLE, link: '/', label: 'Contact'
        },
    ];

    const MenuSingle = (item) => (<li key={item.label}><Link to={item.link}>{item.label}</Link></li>);

    const MenuMultiple = (item) => (
        <li className="menu-icon" key={item.label}>
            <Link to={item.link}>{item.label}</Link>
            <ul>
                {item.subMenu.map(menu => MenuSingle(menu))}
            </ul>
        </li>
    );
    const MenuMega = (item) => (
        <li className="menu-icon">
            <a href={item.link}>{item.label}</a>
            <ul className="mega-menu">
                { item.subMenu.map(menu => MenuMultiple(menu) )}
            </ul>
        </li>
    );

    const MappedMenu = (data) => {
        return data.map(item => {
            if (item.type === MenuTypes.SINGLE) {
                return MenuSingle(item);
            }

            if (item.type === MenuTypes.MULTIPLE) {
                return MenuMultiple(item);
            }

            if (item.type === MenuTypes.MEGA) {
                return MenuMega(item);
            }
        });
    }

    return (
        <div className="header-menu d-none d-xl-block go-top">
            <nav>
                <div className="ltn__main-menu go-top">
                    <ul>
                        {MappedMenu(menus)}
                    </ul>
                </div>
            </nav>
        </div>);
};

export default HeaderMenuDesktop;