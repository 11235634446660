import storage from 'redux-persist/lib/storage';
import {
    persistReducer,
    persistStore,
} from 'redux-persist';
import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import thunk from 'redux-thunk';

import userReducer from './reducers/userReducer';
import dataReducer from './reducers/dataReducer';
import uiReducer from './reducers/uiReducer';
import listingViewReducer from './reducers/listViewReducer';
import lightboxReducer from "./reducers/lightboxReducer";
import intelligentSearchReducer from "./reducers/intelligentSearchReducer";
import {isDevelopment} from '../services/envService';

const middleWare = [thunk];

const persistConfig = {
    key: 'storage',
    storage,
    blacklist:['listingView', 'lightbox', 'ui']
};

const reducers = combineReducers({
    user: userReducer,
    data: dataReducer,
    ui: uiReducer,
    intelligentSearch: intelligentSearchReducer,
    lightbox: lightboxReducer,
    listingView:  listingViewReducer,

});

const persistedReducer = persistReducer(persistConfig, reducers);

const {__REDUX_DEVTOOLS_EXTENSION_COMPOSE__} = window;

const composeEnhancers =
    typeof window === 'object' &&
    isDevelopment() &&
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? __REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
            trace: true,
            traceLimit: 25,
        })
        : compose;

const enhancer = composeEnhancers(applyMiddleware(...middleWare));

export default () => {
    const store = createStore(persistedReducer, {}, enhancer);
    const persistor = persistStore(store);
    return {store, persistor};
};
