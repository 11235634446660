import React from 'react';
import SearchForm from '../components/search/SearchForm';
import LatestListings from '../components/sections/LatestListings';
import Features from '../components/sections/Features';
import CallAction from "../components/sections/CallAction";
import IntelligentSearch from "../components/search/IntelligentSearch";
import useGA from "../hooks/analytics";
import Seo from "../components/SEO/SEO";

function HomePage() {

    const ga = useGA('Home');

    return (
        <>
            <Seo
                title="Caseplex - Complexul caselor imobiliare din Romania"
                description="Cauta anunturi vanzari inchirieri imobiliare din toata Romania pe cea mai noua platforma."
            />
            <IntelligentSearch/>
            <SearchForm/>
            <LatestListings/>
            <Features customClass="ltn__feature-area pt-120 pb-90 mb-120--- bg-light"/>
            <CallAction/>
        </>
    );
}

export default HomePage;

