import {ACCOUNT} from "../enums/account";
import * as yup from "yup";


export const initialValues = {
    email: '',
    password: '',
}

export const validationSchema = yup.object().shape({
    email: yup.string()
        .required('Adresa de email este obligatorie')
        .email('Email-ul nu este valid'),
    password: yup.string().required('Parola este obligatorie').min(6, 'Trebuie sa fie peste 6 charactere').max('100', 'Trebuie sa fie sub 100 charactere'),
});

export const fieldNames = {
    email: 'email',
    password: 'password',
}

export default {initialValues, validationSchema, fieldNames};