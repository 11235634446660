import {LIGHTBOX_IMAGES, LIGHTBOX_INDEX, LIGHTBOX_SET_OPEN} from '../types';

const initialState = {
    open: false,
    images: [],
    index: 0,
};
/**
 * data reducer
 * @param state
 * @param action
 * @return {{search: {}}}
 */
export default function(state = initialState, action) {
    switch (action.type) {
        case LIGHTBOX_SET_OPEN:
            return {
                ...state,
                open: action.payload,
            };
        case LIGHTBOX_IMAGES:
            return {
                ...state,
                images: action.payload,
            };
        case LIGHTBOX_INDEX:
            return {
                ...state,
                index: action.payload,
            };
        default:
            return state;
    }
}
