import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {setRedirect} from "../../redux/actions/dataActions";

const Redirector = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const redirect = useSelector((state) => state.data.redirect);

    useEffect(()=>{
        if (redirect) {
            history.push(redirect);
            dispatch(setRedirect(null));
        }
    }, [redirect])

    return (
        <>

        </>
    );
};

export default Redirector;