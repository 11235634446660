import React, {useEffect} from 'react';
import {Carousel} from "react-responsive-carousel";
import {v4 as uuid} from 'uuid';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {useDispatch} from "react-redux";
import {setImagesLightbox, setIndexLightbox, setOpenLightbox} from "../../../../redux/actions/lightboxActions";

const Slider = ({listing}) => {
    const dispatch = useDispatch();
    const mainImage = listing.isThirdParty ? listing.thirdParty.mainImage : listing.mainImage;
    const images = listing.isThirdParty ? listing.thirdParty.images : listing.images;

    useEffect(() => {
        dispatch(setImagesLightbox(images));
    }, []);

    return (
        <>
            <h4 className="title-2">Imagini</h4>
            <div className="ltn__img-slider-area mb-10 mt-10" style={{cursor: 'zoom-in'}}>
                {images.length < 2 &&
                    <img
                        src={mainImage}
                        alt={listing.title}
                        style={{
                            display: 'block',
                            width: '100%',
                            maxHeight: 450,
                            objectFit: 'cover'
                        }}
                        onClick={() => {
                            dispatch(setIndexLightbox(0));
                            dispatch(setOpenLightbox(true));
                        }}
                    />}
                {images.length > 1 && (
                    <Carousel
                        showThumbs
                        swipeable
                        infiniteLoop
                        useKeyboardArrows
                        onClickItem={(index) => {
                            dispatch(setIndexLightbox(index));
                            dispatch(setOpenLightbox(true));
                        }}
                    >
                        {images.map(image => (
                            <img src={image} alt={listing.title} key={uuid()}
                                 style={{display: 'block', width: '100%', maxHeight: 450, objectFit: 'cover'}}/>
                        ))}
                    </Carousel>
                )}
            </div>
        </>
    );
};

export default Slider;