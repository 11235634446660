import React from 'react';
import {Link} from 'react-router-dom';
import {Button, capitalize, Slide} from "@mui/material";
import Characteristics from "./ListingDetails/Characteristics";
import Meta from "./ListingDetails/Meta";
import Description from "./ListingDetails/Description";
import Details from "./ListingDetails/Details";
import Utilities from "./ListingDetails/Utilities";
import Slider from "./ListingDetails/Slider";
import Sticky from 'react-stickynode';

const ListingDetails = ({listing}) => {

    let publicUrl = process.env.PUBLIC_URL + '/';
    let sellerImage = '';
    if (listing.isThirdParty) {
        if (listing.thirdParty.source === 'Imobiliare.ro') sellerImage = 'https://www.imobiliare.ro/imagini/v8/logo-red-594x112.png';
        if (listing.thirdParty.source === 'Storia.ro') sellerImage = 'https://statics.storia.ro/fp_statics/images/logo/storia_color.png';
    }

    return (
        <div className="ltn__shop-details-area pb-10">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="ltn__shop-details-inner ltn__page-details-inner mb-60">
                            <Meta listing={listing}/>
                            <Slider listing={listing}/>
                            <Description listing={listing}/>
                            <Characteristics listing={listing}/>
                            <Details listing={listing}/>
                            <Utilities listing={listing}/>

                            {/*<h4 className="title-2">Location</h4>*/}
                            {/*<div className="property-details-google-map mb-60">*/}
                            {/*    <iframe*/}
                            {/*        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9334.271551495209!2d-73.97198251485975!3d40.668170674982946!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25b0456b5a2e7%3A0x68bdf865dda0b669!2sBrooklyn%20Botanic%20Garden%20Shop!5e0!3m2!1sen!2sbd!4v1590597267201!5m2!1sen!2sbd"*/}
                            {/*        width="100%" height="100%" frameBorder={0} allowFullScreen aria-hidden="false"*/}
                            {/*        tabIndex={0}/>*/}
                            {/*</div>*/}
                            {/*<h4 className="title-2">Proprietati similare</h4>*/}
                            {/*<div className="row">*/}
                            {/*    /!* ltn__product-item *!/*/}
                            {/*    <div className="col-xl-6 col-sm-6 col-12 go-top">*/}
                            {/*        <div*/}
                            {/*            className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">*/}
                            {/*            <div className="product-img">*/}
                            {/*                <Link to="/shop"><img src={publicUrl + "assets/img/product-3/1.jpg"}*/}
                            {/*                                      alt="#"/></Link>*/}
                            {/*                <div className="real-estate-agent">*/}
                            {/*                    <div className="agent-img">*/}
                            {/*                        <Link to="/team-details"><img*/}
                            {/*                            src="https://www.imobiliare.ro/imagini/v8/logo-red-594x112.png"*/}
                            {/*                            alt="#"/></Link>*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*            <div className="product-info">*/}
                            {/*                <div className="product-badge">*/}
                            {/*                    <ul>*/}
                            {/*                        <li className="sale-badg">For Rent</li>*/}
                            {/*                    </ul>*/}
                            {/*                </div>*/}
                            {/*                <h2 className="product-title"><Link to="/shop">New Apartment Nice*/}
                            {/*                    View</Link></h2>*/}
                            {/*                <div className="product-img-location">*/}
                            {/*                    <ul>*/}
                            {/*                        <li>*/}
                            {/*                            <Link to="/shop"><i className="flaticon-pin"/> Belmont Gardens,*/}
                            {/*                                Chicago</Link>*/}
                            {/*                        </li>*/}
                            {/*                    </ul>*/}
                            {/*                </div>*/}
                            {/*                <ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">*/}
                            {/*                    <li><span>3 </span>*/}
                            {/*                        Bedrooms*/}
                            {/*                    </li>*/}
                            {/*                    <li><span>2 </span>*/}
                            {/*                        Bathrooms*/}
                            {/*                    </li>*/}
                            {/*                    <li><span>3450 </span>*/}
                            {/*                        square Ft*/}
                            {/*                    </li>*/}
                            {/*                </ul>*/}
                            {/*                <div className="product-hover-action">*/}
                            {/*                    <ul>*/}
                            {/*                        <li>*/}
                            {/*                            <a href="#" title="Quick View" data-bs-toggle="modal"*/}
                            {/*                               data-bs-target="#quick_view_modal">*/}
                            {/*                                <i className="flaticon-expand"/>*/}
                            {/*                            </a>*/}
                            {/*                        </li>*/}
                            {/*                        <li>*/}
                            {/*                            <a href="#" title="Wishlist" data-bs-toggle="modal"*/}
                            {/*                               data-bs-target="#liton_wishlist_modal">*/}
                            {/*                                <i className="flaticon-heart-1"/></a>*/}
                            {/*                        </li>*/}
                            {/*                        <li>*/}
                            {/*                            <Link to="/shop" title="Compare">*/}
                            {/*                                <i className="flaticon-add"/>*/}
                            {/*                            </Link>*/}
                            {/*                        </li>*/}
                            {/*                    </ul>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*            <div className="product-info-bottom">*/}
                            {/*                <div className="product-price">*/}
                            {/*                    <span>$349,00<label>/Month</label></span>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*    /!* ltn__product-item *!/*/}
                            {/*    <div className="col-xl-6 col-sm-6 col-12 go-top">*/}
                            {/*        <div*/}
                            {/*            className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">*/}
                            {/*            <div className="product-img">*/}
                            {/*                <Link to="/shop"><img src={publicUrl + "assets/img/product-3/2.jpg"}*/}
                            {/*                                      alt="#"/></Link>*/}
                            {/*                <div className="real-estate-agent">*/}
                            {/*                    <div className="agent-img">*/}
                            {/*                        <Link to="/team-details"><img*/}
                            {/*                            src={publicUrl + "assets/img/blog/author.jpg"} alt="#"/></Link>*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*            <div className="product-info">*/}
                            {/*                <div className="product-badge">*/}
                            {/*                    <ul>*/}
                            {/*                        <li className="sale-badg">For Sale</li>*/}
                            {/*                    </ul>*/}
                            {/*                </div>*/}
                            {/*                <h2 className="product-title"><Link to="/shop">New Apartment Nice*/}
                            {/*                    View</Link></h2>*/}
                            {/*                <div className="product-img-location">*/}
                            {/*                    <ul>*/}
                            {/*                        <li>*/}
                            {/*                            <Link to="/shop"><i className="flaticon-pin"/> Belmont Gardens,*/}
                            {/*                                Chicago</Link>*/}
                            {/*                        </li>*/}
                            {/*                    </ul>*/}
                            {/*                </div>*/}
                            {/*                <ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">*/}
                            {/*                    <li><span>3 </span>*/}
                            {/*                        Bedrooms*/}
                            {/*                    </li>*/}
                            {/*                    <li><span>2 </span>*/}
                            {/*                        Bathrooms*/}
                            {/*                    </li>*/}
                            {/*                    <li><span>3450 </span>*/}
                            {/*                        square Ft*/}
                            {/*                    </li>*/}
                            {/*                </ul>*/}
                            {/*                <div className="product-hover-action">*/}
                            {/*                    <ul>*/}
                            {/*                        <li>*/}
                            {/*                            <a href="#" title="Quick View" data-bs-toggle="modal"*/}
                            {/*                               data-bs-target="#quick_view_modal">*/}
                            {/*                                <i className="flaticon-expand"/>*/}
                            {/*                            </a>*/}
                            {/*                        </li>*/}
                            {/*                        <li>*/}
                            {/*                            <a href="#" title="Wishlist" data-bs-toggle="modal"*/}
                            {/*                               data-bs-target="#liton_wishlist_modal">*/}
                            {/*                                <i className="flaticon-heart-1"/></a>*/}
                            {/*                        </li>*/}
                            {/*                        <li>*/}
                            {/*                            <a href="portfolio-details.html" title="Compare">*/}
                            {/*                                <i className="flaticon-add"/>*/}
                            {/*                            </a>*/}
                            {/*                        </li>*/}
                            {/*                    </ul>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*            <div className="product-info-bottom">*/}
                            {/*                <div className="product-price">*/}
                            {/*                    <span>$349,00<label>/Month</label></span>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <Sticky enabled top={10}>
                            <aside className="sidebar ltn__shop-sidebar ltn__right-sidebar---">
                                {/* Author Widget */}
                                <div className="widget ltn__author-widget">
                                    <div className="ltn__author-widget-inner text-center">
                                        <img
                                            src={sellerImage}
                                            alt="Image"/>
                                        <h4>Adaugat
                                            de {listing.isThirdParty ? listing.thirdParty.source : listing.seller.name}</h4>
                                        {listing.isThirdParty && (
                                            <>
                                                <small>Acest anunt este luat de pe o sursa terta.</small>
                                                <Button className="mt-3" href={listing.thirdParty.url} rel="nofollow"
                                                        target="_blank">Contacteaza proprietarul</Button>
                                            </>
                                        )}
                                    </div>
                                </div>
                                {/* Popular Product Widget */}
                                {/*<div className="widget ltn__popular-product-widget">*/}
                                {/*    <h4 className="ltn__widget-title ltn__widget-title-border-2">Proprietati populare</h4>*/}
                                {/*    <div className="row ltn__popular-product-widget-active slick-arrow-1">*/}
                                {/*        /!* ltn__product-item *!/*/}
                                {/*        <div className="col-12">*/}
                                {/*            <div*/}
                                {/*                className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">*/}
                                {/*                <div className="product-img go-top">*/}
                                {/*                    <Link to="/shop"><img src={publicUrl + "assets/img/product-3/6.jpg"}*/}
                                {/*                                          alt="#"/></Link>*/}
                                {/*                    <div className="real-estate-agent">*/}
                                {/*                        <div className="agent-img">*/}
                                {/*                            <Link to="/team-details"><img*/}
                                {/*                                src={publicUrl + "assets/img/blog/author.jpg"}*/}
                                {/*                                alt="#"/></Link>*/}
                                {/*                        </div>*/}
                                {/*                    </div>*/}
                                {/*                </div>*/}
                                {/*                <div className="product-info">*/}
                                {/*                    <div className="product-price">*/}
                                {/*                        <span>$349,00<label>/Month</label></span>*/}
                                {/*                    </div>*/}
                                {/*                    <h2 className="product-title"><Link to="/shop">New Apartment Nice*/}
                                {/*                        View</Link></h2>*/}
                                {/*                    <div className="product-img-location">*/}
                                {/*                        <ul>*/}
                                {/*                            <li>*/}
                                {/*                                <Link to="/shop"><i className="flaticon-pin"/> Belmont*/}
                                {/*                                    Gardens, Chicago</Link>*/}
                                {/*                            </li>*/}
                                {/*                        </ul>*/}
                                {/*                    </div>*/}
                                {/*                    <ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">*/}
                                {/*                        <li><span>3 </span>*/}
                                {/*                            Bedrooms*/}
                                {/*                        </li>*/}
                                {/*                        <li><span>2 </span>*/}
                                {/*                            Bathrooms*/}
                                {/*                        </li>*/}
                                {/*                        <li><span>3450 </span>*/}
                                {/*                            square Ft*/}
                                {/*                        </li>*/}
                                {/*                    </ul>*/}
                                {/*                </div>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*        /!* ltn__product-item *!/*/}
                                {/*        <div className="col-12">*/}
                                {/*            <div*/}
                                {/*                className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">*/}
                                {/*                <div className="product-img">*/}
                                {/*                    <a href="product-details.html"><img*/}
                                {/*                        src={publicUrl + "assets/img/product-3/4.jpg"} alt="#"/></a>*/}
                                {/*                    <div className="real-estate-agent">*/}
                                {/*                        <div className="agent-img">*/}
                                {/*                            <Link to="/team-details"><img*/}
                                {/*                                src={publicUrl + "assets/img/blog/author.jpg"}*/}
                                {/*                                alt="#"/></Link>*/}
                                {/*                        </div>*/}
                                {/*                    </div>*/}
                                {/*                </div>*/}
                                {/*                <div className="product-info">*/}
                                {/*                    <div className="product-price">*/}
                                {/*                        <span>$349,00<label>/Month</label></span>*/}
                                {/*                    </div>*/}
                                {/*                    <h2 className="product-title"><a href="product-details.html">New*/}
                                {/*                        Apartment Nice View</a></h2>*/}
                                {/*                    <div className="product-img-location">*/}
                                {/*                        <ul>*/}
                                {/*                            <li>*/}
                                {/*                                <a href="product-details.html"><i*/}
                                {/*                                    className="flaticon-pin"/> Belmont Gardens, Chicago</a>*/}
                                {/*                            </li>*/}
                                {/*                        </ul>*/}
                                {/*                    </div>*/}
                                {/*                    <ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">*/}
                                {/*                        <li><span>3 </span>*/}
                                {/*                            Bedrooms*/}
                                {/*                        </li>*/}
                                {/*                        <li><span>2 </span>*/}
                                {/*                            Bathrooms*/}
                                {/*                        </li>*/}
                                {/*                        <li><span>3450 </span>*/}
                                {/*                            square Ft*/}
                                {/*                        </li>*/}
                                {/*                    </ul>*/}
                                {/*                </div>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*        /!* ltn__product-item *!/*/}
                                {/*        <div className="col-12">*/}
                                {/*            <div*/}
                                {/*                className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">*/}
                                {/*                <div className="product-img">*/}
                                {/*                    <a href="product-details.html"><img*/}
                                {/*                        src={publicUrl + "assets/img/product-3/5.jpg"} alt="#"/></a>*/}
                                {/*                    <div className="real-estate-agent">*/}
                                {/*                        <div className="agent-img">*/}
                                {/*                            <Link to="/team-details"><img*/}
                                {/*                                src={publicUrl + "assets/img/blog/author.jpg"}*/}
                                {/*                                alt="#"/></Link>*/}
                                {/*                        </div>*/}
                                {/*                    </div>*/}
                                {/*                </div>*/}
                                {/*                <div className="product-info">*/}
                                {/*                    <div className="product-price">*/}
                                {/*                        <span>$349,00<label>/Month</label></span>*/}
                                {/*                    </div>*/}
                                {/*                    <h2 className="product-title"><a href="product-details.html">New*/}
                                {/*                        Apartment Nice View</a></h2>*/}
                                {/*                    <div className="product-img-location">*/}
                                {/*                        <ul>*/}
                                {/*                            <li>*/}
                                {/*                                <a href="product-details.html"><i*/}
                                {/*                                    className="flaticon-pin"/> Belmont Gardens, Chicago</a>*/}
                                {/*                            </li>*/}
                                {/*                        </ul>*/}
                                {/*                    </div>*/}
                                {/*                    <ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">*/}
                                {/*                        <li><span>3 </span>*/}
                                {/*                            Bedrooms*/}
                                {/*                        </li>*/}
                                {/*                        <li><span>2 </span>*/}
                                {/*                            Bathrooms*/}
                                {/*                        </li>*/}
                                {/*                        <li><span>3450 </span>*/}
                                {/*                            square Ft*/}
                                {/*                        </li>*/}
                                {/*                    </ul>*/}
                                {/*                </div>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*        /!*  *!/*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/* Banner Widget */}
                                <div className="widget ltn__banner-widget d-none go-top">
                                    <Link to="/shop"><img src={publicUrl + "assets/img/banner/2.jpg"} alt="#"/></Link>
                                </div>
                            </aside>
                        </Sticky>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ListingDetails;
