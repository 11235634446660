import React, {useState} from 'react';
import GridListing from './GridListing';
import {SEARCH_DISPLAY} from '../../types/search';
import ListListing from './ListListing';
import {v4 as uuid} from 'uuid';
import ListingViewModal from "./ListingViewModal";
import Lightbox from "./ListingDetails/ListingDetails/Lightbox";

const ListingsContainer = ({display, listings}) => {
    let Listings;
    if (display === SEARCH_DISPLAY.GRID) {
        Listings = (
            <div className="ltn__product-tab-content-inner ltn__product-grid-view">
                    <div
                        className="row"
                    >
                        {
                            listings.map(listing => <GridListing listing={listing} key={uuid()}/>)
                        }
                    </div>
            </div>
        )
    }

    if (display === SEARCH_DISPLAY.LIST) {
        Listings = (
            <div className="ltn__product-tab-content-inner ltn__product-list-view">
                {listings.map(listing => <ListListing listing={listing}/>)}
            </div>
        )
    }

    return (
        <>
            {Listings}
            <ListingViewModal/>
            <Lightbox/>
        </>
    );
}

export default ListingsContainer;
