import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import httpService from "../../../services/httpService";
import InputText from "../../common/InputText";
import CreateValidator from "../../../utils/validator";
import {ValidationForm} from "../../common/ValidationForm";
import validationSchema from "../../../validation/register";
import InputCheckbox from "../../common/InputCheckbox";
import InputRadioGroup from "../../common/InputRadioGroup";
import {ACCOUNT} from "../../../enums/account";
import LoadingButton from '@mui/lab/LoadingButton';
import {useDispatch} from "react-redux";
import {
    setUserActive,
    setUserEmail,
    setUserFirstname,
    setUserLastname,
    setUserLogged, updateUserData
} from "../../../redux/actions/userActions";
import pathService from "../../../services/pathService";
import {useHistory} from "react-router-dom";

export default function Register() {

    const history = useHistory();
    const dispatch = useDispatch();

    const translations = {
        title: "Inregistrare",
        labels: {
            account: "Tip de cont",
            firstname: "Prenume",
            lastname: "Nume",
            email: "Adresa de email",
            password: "Parola",
            termsAndCond: "Termeni si conditii",
            submit: "Inregistare",
            forgot: "Ai uitat parola?",
            login: "Logheaza-te"
        }
    }

    const handleSubmit = (data, actions) => {
        httpService
            .post('/register', data)
            .then(({data: {firstname, lastname, email}}) => {
                dispatch(updateUserData({firstname, lastname, email}));
                dispatch(setUserActive(false));
                dispatch(setUserLogged(true));
                history.push(pathService.pages.activation);
            })
            .catch(error => {
                if (error.response?.data?.formik) {
                    actions.setErrors(error.response.data?.formik);
                    actions.setSubmitting(false);
                }
            })
    }


    const validator = CreateValidator(validationSchema, handleSubmit);

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <Box
                sx={{
                    margin: '50px 0',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                    <AccountCircleIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    {translations.title}
                </Typography>

                <ValidationForm validator={validator}>
                    <InputRadioGroup
                        disabled={validator.isSubmitting}
                        name={validationSchema.fieldNames.account}
                        label={translations.labels.account}
                        values={[
                            {label: 'Persoana fizica', value: ACCOUNT.INDIVIDUAL},
                            {label: 'Agentie', value: ACCOUNT.AGENCY},
                        ]}
                    />
                    <InputText
                        disabled={validator.isSubmitting}
                        name={validationSchema.fieldNames.firstname}
                        label={translations.labels.firstname}
                    />
                    <InputText
                        disabled={validator.isSubmitting}
                        name={validationSchema.fieldNames.lastname}
                        label={translations.labels.lastname}
                    />
                    <InputText
                        disabled={validator.isSubmitting}
                        name={validationSchema.fieldNames.email}
                        label={translations.labels.email}
                        autoComplete="email"
                    />
                    <InputText
                        disabled={validator.isSubmitting}
                        password
                        name={validationSchema.fieldNames.password}
                        label={translations.labels.password}
                    />
                    <InputCheckbox
                        disabled={validator.isSubmitting}
                        name={validationSchema.fieldNames.termsAndCond}
                        label={translations.labels.termsAndCond}
                    />
                    <LoadingButton
                        disabled={!validator.dirty || validator.isSubmitting}
                        loading={validator.isSubmitting}
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{mt: 3, mb: 2}}
                    >
                        {translations.labels.submit}
                    </LoadingButton>
                </ValidationForm>
            </Box>
        </Container>
    );
}