import {
    ADD_FAVOURITE,
    ADD_LOCAL_USER_SAVED,
    ADD_USER_SAVED,
    REMOVE_USER_LOCAL_SAVED,
    REMOVE_USER_SAVED,
    SET_REMOVE_USER_SAVED,
    SET_USER_ACTIVE,
    SET_USER_EMAIL,
    SET_USER_FIRSTNAME,
    SET_USER_LASTNAME,
    SET_USER_LISTINGS,
    SET_USER_LOGGED,
    SET_USER_PENDING_LISTINGS,
    SET_USER_SAVED,
    SET_USER_SETTINGS,
    SET_USER_TYPE, UPDATE_USER_DATA,
    USER_LOGOUT
} from '../types';

const initialState = {
    firstname: null,
    lastname: null,
    userType: null,
    email: null,
    active: false,
    logged: false,
    removeSavedId: null,
    saved: [],
    localSaved: [],
    listings: [],
    pendingListings: [],
    settings: {},
};
/**
 * data reducer
 * @param state
 * @param action
 * @return {{search: {}}}
 */
export default function(state = initialState, action) {
    switch (action.type) {
        case SET_USER_FIRSTNAME:
            return {
                ...state,
                firstname: action.payload,
            }
        case SET_USER_LASTNAME:
            return {
                ...state,
                lastname: action.payload,
            }
        case ADD_USER_SAVED:
            return {
                ...state,
                saved: state.saved.includes(action.payload) ? state.saved : [...state.saved, action.payload],
            };
        case ADD_LOCAL_USER_SAVED:
            return {
                ...state,
                localSaved: state.localSaved.includes(action.payload) ? state.localSaved : [...state.localSaved, action.payload],
            };
        case SET_USER_SAVED:
            return {
                ...state,
                saved: action.payload,
            }
        case SET_USER_EMAIL:
            return {
                ...state,
                email: action.payload,
            }
        case SET_USER_ACTIVE:
            return {
                ...state,
                active: action.payload,
            }
        case SET_USER_LOGGED:
            return {
                ...state,
                logged: true,
            }
        case SET_USER_LISTINGS:
            return {
                ...state,
                listings: action.payload,
            }
        case SET_USER_PENDING_LISTINGS:
            return {
                ...state,
                pendingListings: action.payload,
            }
        case SET_USER_TYPE:
            return {
                ...state,
                userType: action.payload
            }
        case SET_USER_SETTINGS:
            return {
                ...state,
                settings: action.payload,
            }
        case REMOVE_USER_SAVED:
            return {
                ...state,
                saved: state.saved.filter(item => item !== action.payload),
            }
        case REMOVE_USER_LOCAL_SAVED:
            return {
                ...state,
                savedLocal: state.savedLocal.filter(item => item !== action.payload),
            }
        case SET_REMOVE_USER_SAVED:
            return {
                ...state,
                removeSavedId: action.payload,
            }
        case UPDATE_USER_DATA:
            return {
                ...state,
                ...action.payload,
            }
        case USER_LOGOUT:
            return initialState;
        default:
            return state;
    }
}
