import React, {useContext} from 'react';
import {FormControl, FormHelperText, FormLabel, Radio, RadioGroup} from "@mui/material";
import {ValidatorContext} from "./ValidationForm";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const InputRadioGroup = ({name, label, values, disabled, ...rest}) => {
    const validator = useContext(ValidatorContext);

    return (
        <FormControl error={!!validator.touched[name] && !!validator.errors[name]} >
            <FormLabel id={name}>{label}</FormLabel>
            <RadioGroup
                row
                aria-labelledby={name}
                name={name}
                value={validator.values[name]}
                onChange={validator.handleChange}
                {...rest}
            >
                {values.map((radio) => <FormControlLabel disabled={disabled} key={radio.value} label={radio.label} value={radio.value} control={<Radio/>}/>)}
            </RadioGroup>
            {!!validator.touched[name] && validator.errors[name] && (
                <FormHelperText>{validator.errors[name]}</FormHelperText>
            )}
        </FormControl>
    );
};

export default InputRadioGroup;