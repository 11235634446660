import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import httpService from "../../services/httpService";
import pathService from "../../services/pathService";
import InfiniteScroll from "react-infinite-scroll-component";
import {Box, CircularProgress, Grid, Typography} from "@mui/material";
import {CheckCircleRounded} from "@mui/icons-material";
import ListingsContainer from "../../components/listing/ListingsContainer";
import {setRemoveUserSaved} from "../../redux/actions/userActions";

const UserSavedPage = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const [results, setResults] = useState([]);
    const [totalResults, setTotalResults] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [offset, setOffset] = useState(0);
    const limit = 36;

    const fetchNextData = async () => {
        if (user.logged) {
            httpService
                .get(pathService.api.getSaved, { params: { offset, limit }})
                .then(({data}) => {
                    const {results, hasMore, totalResults} = data;
                    setOffset(prevOffset => prevOffset + limit);
                    setResults(prevResults => {
                        return prevResults.concat(results)
                    });
                    setTotalResults(totalResults);
                    setHasMore(hasMore);
                })
        } else {
            httpService
                .get(pathService.api.getLocalSaved, {params: {ids: user.saved, offset, limit}})
                .then(({data}) => {
                    const {results, hasMore, totalResults} = data;
                    setOffset(prevOffset => prevOffset + limit);
                    setResults(prevResults => {
                        return prevResults.concat(results)

                    });
                    setTotalResults(totalResults);
                    setHasMore(hasMore);
                })
        }
    };

    useEffect(() => {
        if (user.logged) {
            httpService
                .get(pathService.api.getSaved)
                .then(({data}) => {
                    const {results, hasMore, totalResults} = data;
                    setOffset(prevOffset => prevOffset + limit);
                    setResults(prevResults => {
                        return prevResults.concat(results)
                    });
                    setTotalResults(totalResults);
                    setHasMore(hasMore);
                })
        } else {
            httpService
                .get(pathService.api.getLocalSaved, { params: { ids: user.localSaved, offset, limit}})
                .then(({data}) => {
                    const {results} = data;
                    setResults(results);
                })
        }
    }, []);

    useEffect(() => {
        if (user.removeSavedId) {
            setResults(prevResults => prevResults.filter(result => result.id !== user.removeSavedId));
            dispatch(setRemoveUserSaved(null));
        }
    }, [user.removeSavedId])

    return (
        <>
            <InfiniteScroll
                dataLength={results?.length}
                next={fetchNextData}
                hasMore={hasMore}
                loader={
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        sx={{width: '100%', margin: '30px 0'}}
                    >
                        <p>Se incarca mai multe anunturi</p>
                        <CircularProgress size={80}/>
                    </Box>
                }
                endMessage={
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        sx={{width: '100%', margin: '30px 0', minHeight: '60vh'}}
                    >
                        { results.length === 0 && (<Typography variant="subtitle1" component="h4">Nu ai niciun anunt la salvate.</Typography>) }
                        { results.length !== 0 && (
                            <>
                                <CheckCircleRounded fontSize="large" sx={{color: 'primary.main'}}/>
                                Acestea au fost toate rezultatele.
                            </>
                        ) }
                    </Box>
                }
            >
                <div className="ltn__product-area ltn__product-gutter mb-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="tab-content">
                                    <ListingsContainer display={'GRID'} listings={results || []}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </InfiniteScroll>
        </>

    );
};

export default UserSavedPage;