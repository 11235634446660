import React from 'react';
import {Typography} from "@mui/material";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const NotFound = () => {
    return (
        <>

            <Typography variant="h4" textAlign="center" style={{ minHeight: '70vh', marginTop: 50}}>
                <HighlightOffIcon color="error" fontSize="large" className="mr-30" />
                Pagina nu a fost gasita.
            </Typography>
        </>
    );
};

export default NotFound;