import {ACCOUNT} from "../enums/account";
import * as yup from "yup";

export const initialValues = {
    firstname: '',
    lastname: '',
    email: '',
    account: ACCOUNT.INDIVIDUAL,
}

export const validationSchema = yup.object().shape({
    firstname: yup.string().required('Prenumele este obligatoriu'),
    lastname: yup.string().required('Numele este obligatoriu'),
    email: yup.string()
        .required('Adresa de email este obligatorie')
        .email('Email-ul nu este valid'),
    account: yup.string().required('Tip de cont este obligatoriu'),
});

export const fieldNames = {
    firstname: 'firstname',
    lastname: 'lastname',
    email: 'email',
    account: 'account',
}

export default {initialValues, validationSchema, fieldNames};