import React, {useEffect, useState} from 'react';
import {
    AppBar, Autocomplete, Backdrop,
    Box,
    Button, CircularProgress,
    Dialog,
    Grid,
    IconButton,
    Slide,
    Step, StepContent,
    StepLabel,
    Stepper,
    Toolbar,
    Typography
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {addSessionIS, setOpenIS} from "../../redux/actions/intelligentSearchActions";
import {Close} from "@mui/icons-material";
import Localization from "./IntelligentSearch/Localization";
import Budget from "./IntelligentSearch/Budget";
import {formatCurrency, formatNumber} from "../../utils/format";
import httpService from "../../services/httpService";
import {isMobile, MobileView, BrowserView, isDesktop} from "react-device-detect";
import Areas from "./IntelligentSearch/Areas";
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import Rooms from "./IntelligentSearch/Rooms";
import BuiltYear from "./IntelligentSearch/BuiltYear";
import {useHistory} from "react-router-dom";
import pathService from "../../services/pathService";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const optionalSteps = [];
const steps = [
    {step: 0, label: 'Locatie'},
    {step: 1, label: 'Buget'},
    {step: 2, label: 'Zone'},
    {step: 3, label: 'Camere'},
    {step: 4, label: 'An'},
];

const initialStates = {
    currentSearch: {
        county: 'bucuresti ilfov',
        city: 'bucuresti',
        budget: '',
    }
}

const IntelligentSearch = () => {

    const history = useHistory();
    const dispatch = useDispatch();
    const {open} = useSelector((state) => state.intelligentSearch);

    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [analytics, setAnalytics] = useState({general: 0, areas: []});

    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());
    const [currentSearch, setCurrentSearch] = useState(initialStates.currentSearch);

    const isStepOptional = (step) => {
        return optionalSteps.includes(step);
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const disableNextStep = () => {
        if (activeStep === 1 && !!!currentSearch.budget) return true;
        if (activeStep === 2 && !currentSearch.areas?.length) return true;
        if (activeStep === 3 && !currentSearch.rooms?.length) return true;
        if (activeStep === 4 && !currentSearch.builtYear?.length) return true;
        return false;
    }

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }
        const nextStep = activeStep + 1;

        if (nextStep === 1) {
            setOpenBackdrop(true);
            httpService
                .post('/search/ai/general', currentSearch)
                .then(({data}) => {
                    const {result} = data;
                    setAnalytics(prev => ({
                        ...prev,
                        general: result
                    }));
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    setSkipped(newSkipped);
                    setOpenBackdrop(false);
                })
        } else if (nextStep === 2) {
            setOpenBackdrop(true);
            httpService
                .post('/search/ai/areas', {...currentSearch, budget: formatNumber(currentSearch.budget)})
                .then(({data}) => {
                    const {results} = data;
                    setAnalytics(prev => ({
                        ...prev,
                        areas: results
                    }));
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    setSkipped(newSkipped);
                    setOpenBackdrop(false);
                })
        } else if (nextStep === 3) {
            setOpenBackdrop(true);
            httpService
                .post('/search/ai/areas/rooms', {...currentSearch, budget: formatNumber(currentSearch.budget)})
                .then(({data}) => {
                    const {results} = data;
                    setAnalytics(prev => ({
                        ...prev,
                        rooms: results
                    }));
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    setSkipped(newSkipped);
                    setOpenBackdrop(false);
                })
        } else if (nextStep === 4) {
            setOpenBackdrop(true);
            httpService
                .post('/search/ai/areas/rooms/built-year', {
                    ...currentSearch,
                    budget: formatNumber(currentSearch.budget)
                })
                .then(({data}) => {
                    const {results} = data;
                    setAnalytics(prev => ({
                        ...prev,
                        builtYear: results
                    }));
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    setSkipped(newSkipped);
                    setOpenBackdrop(false);
                })
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setSkipped(newSkipped);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const handleChange = (event) => {
        const {name, value} = event.target;
        if (name === 'budget') {
            return setCurrentSearch(prev => ({
                ...prev,
                [name]: formatCurrency(value),
            }));
        }
        setCurrentSearch(prev => ({
            ...prev,
            [name]: value,
        }));
    }

    const handleSubmit = () => {
        dispatch(addSessionIS(currentSearch));
        history.push({
            pathname: pathService.pages.intelligentSearch.results,
            state: { session: currentSearch }
        });
        dispatch(setOpenIS(false));
    }

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={() => dispatch(setOpenIS(false))}
            TransitionComponent={Transition}
        >
            <AppBar color="secondary" position="sticky">
                <Toolbar>
                    <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                        Cautare inteligenta
                    </Typography>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => dispatch(setOpenIS(false))}
                        aria-label="close"
                    >
                        <Close/>
                        <Typography variant="h6" component="div">
                            Inchide
                        </Typography>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Box
                sx={{margin: '50px 30px'}}
            >
                <Stepper
                    activeStep={activeStep}
                    // orientation={ isMobile ? 'vertical' : undefined }
                    alternativeLabel
                >
                    {steps.map((item, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        if (isStepOptional(item.optional)) {
                            labelProps.optional = (
                                <Typography variant="caption">Optional</Typography>
                            );
                        }
                        if (isStepSkipped(index)) {
                            stepProps.completed = false;
                        }
                        return (
                            <Step key={item.label} {...stepProps}>
                                <StepLabel {...labelProps}>
                                    {item.label}
                                </StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                {activeStep === steps.length ? (
                        <Grid
                            container
                            spacing={0}
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                            className="mt-3"
                        >
                            <Grid item>
                                <Typography sx={{mt: 1}} variant="h5" textAlign="center">Perfect!</Typography>
                                <Typography sx={{mb: 1}} variant="subtitle2" textAlign="center">
                                    Toti pasii au fost completati. Noi ti-am pregatit rezultate exact pentru ce ai nevoie.
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                                    <Box sx={{flex: '1 1 auto'}}/>
                                    <Button onClick={handleSubmit}  variant="contained" disableElevation>Vezi rezultate</Button>
                                </Box>
                            </Grid>
                        </Grid>
                ) : (
                    <React.Fragment>
                        <Grid
                            container
                            spacing={0}
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                            style={{minHeight: '30vh'}}
                        >

                            <Localization
                                active={activeStep === 0}
                                analytics={analytics}
                                currentSearch={currentSearch}
                                handleChange={handleChange}
                            />
                            <Budget
                                active={activeStep === 1}
                                analytics={analytics}
                                currentSearch={currentSearch}
                                handleChange={handleChange}
                            />
                            <Areas
                                active={activeStep === 2}
                                analytics={analytics}
                                currentSearch={currentSearch}
                                handleChange={handleChange}
                            />
                            <Rooms
                                active={activeStep === 3}
                                analytics={analytics}
                                currentSearch={currentSearch}
                                handleChange={handleChange}
                            />
                            <BuiltYear
                                active={activeStep === 4}
                                analytics={analytics}
                                currentSearch={currentSearch}
                                handleChange={handleChange}
                            />

                            <Grid
                                container

                                direction="row"
                                justifyContent="space-between"
                                sx={{mt: 4}}
                                maxWidth="sm"
                            >
                                <Button
                                    disableElevation
                                    variant="contained"
                                    startIcon={<ArrowCircleLeftIcon/>}
                                    color="inherit"
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    sx={{mr: 1}}
                                >
                                    Inapoi
                                </Button>

                                <Button
                                    disableElevation
                                    variant="contained"
                                    endIcon={<ArrowCircleRightIcon/>}
                                    onClick={handleNext}
                                    disabled={disableNextStep()}
                                >
                                    Urmatorul
                                </Button>
                            </Grid>

                        </Grid>
                    </React.Fragment>
                )}
            </Box>
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={openBackdrop}
            >
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid item xs={3}>
                        <CircularProgress color="inherit"/>
                    </Grid>
                    <Grid item>
                        <Typography variant="h5" color="inherit">Se incarca</Typography>
                    </Grid>
                </Grid>
            </Backdrop>
        </Dialog>
    );
};

export default IntelligentSearch;