import React, {useState} from 'react';
import {
    capitalize,
    FilledInput,
    FormControl,
    FormHelperText,
    Grid,
    InputAdornment,
    InputLabel,
    Typography
} from "@mui/material";
import {formatCurrency, formatNumber} from "../../../utils/format";

const Budget = ({active, currentSearch, analytics, handleChange}) => {
    const [underBudget, setUnderBudget] = useState(false);
    if (!active) return null;
    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            maxWidth="sm"
        >
            <Grid item>
                <Typography sx={{mt: 1}} variant="h5" textAlign="center">Buget</Typography>
                <Typography sx={{mb: 1}} variant="subtitle2" textAlign="center">
                    Care este bugetul maxim pe care il aveti?
                </Typography>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <FormControl
                        variant="filled"
                        fullWidth
                        margin="normal"
                        onBlur={() => !!currentSearch.budget ? setUnderBudget(formatNumber(currentSearch.budget) < analytics.general) : setUnderBudget(false)}
                    >
                        <InputLabel>Buget</InputLabel>
                        <FilledInput
                            name="budget"
                            value={currentSearch.budget}
                            onChange={handleChange}
                            startAdornment={<InputAdornment position="start">EURO</InputAdornment>}
                        />
                        <FormHelperText>Pretul mediu pentru <strong>{capitalize(currentSearch.city)}</strong> este de <strong>{formatCurrency(analytics?.general.toString())} EURO</strong></FormHelperText>
                        { underBudget && (<FormHelperText style={{ color: 'darksalmon' }}>Daca bugetul este sub pretul mediu poate insemna zone mai putin favorabile</FormHelperText>) }
                    </FormControl>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Budget;