import React, {useEffect, useState} from 'react';
import {Button, capitalize, Checkbox, FormControlLabel, Grid, Typography} from "@mui/material";
import {formatCurrency} from "../../../utils/format";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

const Rooms = ({active, currentSearch, analytics, handleChange}) => {
    const [selectedAll, setSelectedAll] = useState(false);
    const [rooms, setRooms] = useState(new Set());

    const handleCheckBoxChange = (e, value) => {
        const {checked} = e.target;
        if (checked) {
            setRooms(previousState => new Set([...previousState, value]));
        } else {
            setRooms(previousState => new Set([...previousState].filter(v => v !== value)));
        }
    }
    const toggleSelectAll = () => {
        if (selectedAll) {
            setSelectedAll(false);
            setRooms(new Set([]));
        } else {
            setSelectedAll(true);
            setRooms(new Set([...analytics.rooms]));
        }
    }

    useEffect(() => {
        handleChange({
            target: {
                name: 'rooms',
                value: [...rooms]
            }
        })
    }, [rooms]);

    if (!active) return null;
    return (
        <Grid
            container
            spacing={0}
            direction="row"
            alignItems="center"
            justifyContent="center"
            maxWidth="md"
        >
            <Grid item>
                <Typography sx={{mt: 1}} variant="h5" textAlign="center">Numar camere</Typography>
                <Typography sx={{mb: 1}} variant="subtitle2" textAlign="center">
                    Selectati numarul de camere pe care le doriti in bugetul de {formatCurrency(currentSearch.budget)} EURO
                </Typography>
            </Grid>
            <Grid container spacing={2} justifyContent="center">
                <Grid
                    item
                    container
                    spacing={0}
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    xs={12} md={12}>
                    <Button
                        variant="contained"
                        disableElevation
                        onClick={() => toggleSelectAll()}
                        startIcon={selectedAll ? <CheckBoxOutlineBlankIcon/> : <CheckBoxIcon/>}
                    >
                        {selectedAll ? 'Deselectati toate rezultatele' : 'Selectati toate rezultatele'}
                    </Button>
                </Grid>
                <Grid
                    item
                    container
                    sx={{
                        m: 1,
                        border: 1,
                        borderRadius: '10px',
                        borderColor: 'primary.main',
                    }}
                >
                    {analytics.rooms?.map(item => (
                        <Grid item xs={6} md={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        sx={{color: 'primary.main'}}
                                        checked={rooms.has(item)}
                                        onChange={(e) => handleCheckBoxChange(e, item)}
                                    />
                                }
                                label={capitalize(item)}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Rooms;