import React, {useEffect, useRef, useState} from 'react';
import ReactMapGL, {Marker, Popup} from 'react-map-gl';
import {getCenter} from "geolib";
import GridListing from "./GridListing";
import {Link} from "react-router-dom";
import {Button, capitalize, Fab} from "@mui/material";
import Services from "../../enums/services";
import {Close, Favorite} from "@mui/icons-material";
import MapListing from "./MapListing";
import httpService from "../../services/httpService";
import HomeIcon from '@mui/icons-material/Home';
import IconButton from "@mui/material/IconButton";
import FavoriteIcon from '@mui/icons-material/Favorite';
import {useSelector} from "react-redux";
import mapboxgl from "mapbox-gl";
import {shortFormatter} from "../../utils/format";
import DarkModeIcon from '@mui/icons-material/DarkMode';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import {isMobile} from 'react-device-detect';

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const mapStyles = {
    day: 'mapbox://styles/mapbox/streets-v11',
    night: 'mapbox://styles/mapbox/dark-v10',
}

const Map = ({listings, search}) => {

    const [results, setResults] = useState(listings);
    const [mapEvent, setMapEvent] = useState(null);

    const shouldUpdate = (oldValue, newValue) => {
        const convert = (value) => Math.round(value * 50) / 50;
        if (parseInt(oldValue.zoom) !== parseInt(newValue.zoom)) return true;
        return convert(oldValue.longitude) !== convert(newValue.longitude) || convert(oldValue.latitude) !== convert(newValue.latitude);
    }

    const [mapSettings, setMapSettings] = useState({
        mapStyle: mapStyles.day,
        style: {
            width: '100%',
            height: isMobile ? '71vh' : '77vh',
        },
    });

    const [viewport, setViewport] = useState({
        ...getCenter(listings.map(listing => ({
            longitude: listing.map.coordinates[0],
            latitude: listing.map.coordinates[1]
        }))),
        zoom: 12
    });

    const [popup, setPopup] = useState(null);


    const handleOnMove = (evt, force = false) => {
        if (shouldUpdate(viewport, evt.viewState) || force) {
            httpService
                .get('/search/geo', {params: {...evt.viewState, ...search}})
                .then(res => {
                    const {results} = res.data;
                    setResults(results);
                });
        }
        setViewport(evt.viewState);
    }

    useEffect(() => {
        if (mapEvent) handleOnMove(mapEvent);
    }, [mapEvent, search]);

    useEffect(() => {
        if (mapEvent) handleOnMove(mapEvent, true);
    }, [search]);

    const user = useSelector((state) => state.user);
    const isToSaved = (id) => user.saved?.includes(id) || user.localSaved?.includes(id);

    const IconView = ({listing}) => {
        if (viewport.zoom > 13.5) {
            return isToSaved(listing._id)
                ? (
                    <Button variant="contained" color="error" startIcon={<FavoriteIcon/>}>
                        {shortFormatter(listing.price)}
                    </Button>
                )
                : (
                    <Button variant="contained">
                        {shortFormatter(listing.price)}
                    </Button>
                )
        } else {
            return (<IconButton variant="outlined">
                {isToSaved(listing._id)
                    ? <FavoriteIcon fontSize="large" color="error" style={{cursor: 'pointer'}}/>
                    : <HomeIcon fontSize="large" color="warning" style={{cursor: 'pointer'}}/>
                }
            </IconButton>)
        }
    }

    const [nightMode, setNightMode] = useState(false)

    const NightMode = () => {
        return (
            <Fab
                onClick={() => {
                    setNightMode(prevState => {
                        const value = !prevState;
                        setMapSettings(prevState => ({ ...prevState, mapStyle: value ? mapStyles.night : mapStyles.day }))
                        return value;
                    });
                }}
                style={{
                    position: 'fixed',
                    bottom: 22,
                    right: 22,
                }}
            >
                {nightMode ? <DarkModeIcon/> : <Brightness7Icon />}
            </Fab>
        )
    }

    return (
        <>
            <ReactMapGL
                mapboxAccessToken="pk.eyJ1IjoiY2FzZXBsZXgiLCJhIjoiY2w1N3FjMTU0MGZ5NjNtbGY3bzcyaTRvYiJ9.AJkJh-AUpXmORjaAfvfW4A"
                onMove={(evt) => setMapEvent(evt)}
                {...mapSettings}
                {...viewport}
            >
                {results.map(listing =>
                    (
                        <div
                            key={listing._id}
                        >
                            <Marker
                                onClick={() => {
                                    setPopup(listing._id);
                                }}
                                longitude={listing.map.coordinates[0]}
                                latitude={listing.map.coordinates[1]}
                            >
                                <IconView listing={listing}/>
                            </Marker>
                            {!!popup && (popup === listing._id) && (
                                <Popup
                                    longitude={listing.map.coordinates[0]}
                                    latitude={listing.map.coordinates[1]}
                                    style={{maxWidth: 350}}
                                    onClose={() => setPopup(null)}
                                >
                                    <MapListing listing={listing}/>
                                </Popup>
                            )}
                        </div>
                    )
                )
                }
            </ReactMapGL>
            <NightMode/>
        </>

    );
};

export default Map;