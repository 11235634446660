import React from 'react';
import {Link} from 'react-router-dom';
import {Box, Button, capitalize, Grid} from "@mui/material";
import {v4 as uuid} from 'uuid';
import {Favorite} from "@mui/icons-material";
import Swal from "sweetalert2";
import Services from "../../enums/services";
import {addUserSaved} from "../../redux/actions/userActions";
import {useDispatch} from "react-redux";

const ListListing = ({listing, onClick}) => {

    const dispatch = useDispatch();
    const isHouse = listing.category === 'apartamente' || listing.category === 'case-vile';
    const isLand = listing.category === 'teren';

    const descriptionList = [
        isHouse && {value: listing.rooms, label: listing.rooms > 1 ? 'camere' : 'camera'},
        isHouse && {value: listing.baths, label: 'bai'},
        isHouse && {value: listing.netArea, label: 'm2'},
        isHouse && {value: listing.builtYear, label: ''},

        isLand && {value: listing.netArea, label: 'm2'},
    ];

    const DescriptionItems = descriptionList.map(item => (
        <li key={uuid()}>
            <span>{item.value} </span>
            {item.label}
        </li>
    ))

    const addToFavourite = () => {
        dispatch(addUserSaved(listing.id));
        Swal.fire({
            icon: 'success',
            title: 'Anunt salvat la favorite.',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
        })
    }

    return (
        <div className="col-lg-12">
            <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5">
                <div className="product-img">
                    <Link to="#" onClick={onClick}>
                        <img style={{width: 368, height: 276, objectFit: 'cover'}} src={
                            (listing.isThirdParty ? listing.thirdParty.mainImage : listing.mainImage)
                            || 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcROJGo_BDmE1BQXej-UemTXxZG6RkDsA95ZnA&usqp=CAU'
                        } alt="#"/>
                    </Link>
                </div>
                <div className="product-info">
                    <div className="product-badge-price">
                        <div className="product-badge">
                            <ul>
                                <li className="sale-badg">{listing.service}</li>
                            </ul>
                        </div>
                        <div className="product-price">
                            <span> {listing.price.toLocaleString('ro-RO')} <label> EURO{listing.service === Services.RENT && ' / luna'}</label></span>
                        </div>
                    </div>
                    <h2 className="product-title go-top">
                        <Link to="#" onClick={onClick}>
                            {listing.title}
                        </Link>
                    </h2>
                    <div className="product-img-location">
                        <ul>
                            <li className="go-top">
                                <Link><i
                                    className="flaticon-pin"/> {capitalize(listing.area ? `${listing.area}, ` : '')} {capitalize(listing.county || '')}, {capitalize(listing.city || '')}
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
                        {DescriptionItems}
                    </ul>
                </div>
                <div className="product-info-bottom">
                    <Grid container style={{marginTop: 50}}>
                        <Button variant="text" onClick={() => addToFavourite()}
                                startIcon={<Favorite/>}>Salveaza</Button>
                    </Grid>
                    {/*<div className="real-estate-agent">*/}
                    {/*  <div className="agent-img">*/}
                    {/*    <Link to="/team-details"><img src={publicUrl + "assets/img/blog/author.jpg"} alt="#" /></Link>*/}
                    {/*  </div>*/}
                    {/*  <div className="agent-brief go-top">*/}
                    {/*    <h6><Link to="/team-details">William Seklo</Link></h6>*/}
                    {/*    <small>Estate Agents</small>*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                    <div className="product-hover-action">
                        {/*<ul>*/}
                        {/*  <li>*/}
                        {/*    <a href="#" title="Quick View" data-bs-toggle="modal"*/}
                        {/*       data-bs-target="#quick_view_modal">*/}
                        {/*      <i className="flaticon-expand" />*/}
                        {/*    </a>*/}
                        {/*  </li>*/}
                        {/*  <li>*/}
                        {/*    <a href="#" title="Wishlist" data-bs-toggle="modal"*/}
                        {/*       data-bs-target="#liton_wishlist_modal">*/}
                        {/*      <i className="flaticon-heart-1" /></a>*/}
                        {/*  </li>*/}
                        {/*  <li>*/}
                        {/*									<span className="go-top">*/}
                        {/*									<Link to="/product-details" title="Product Details">*/}
                        {/*										<i className="flaticon-add" />*/}
                        {/*									</Link>*/}
                        {/*									</span>*/}
                        {/*  </li>*/}
                        {/*</ul>*/}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ListListing;
