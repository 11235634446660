import React, {useEffect} from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {v4 as uuid} from 'uuid';
import {PersistGate} from 'redux-persist/integration/react';
import {history} from './hooks/history';
// import Route from './components/common/Route';

// SERVICES
import pathService from './services/pathService';

// COMPONENTS
import Navbar from './components/global/Navbar';
import Footer from './components/global/Footer';

// PAGES
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import SearchPage from './pages/SearchPage';
import ListingDetailsPage from './pages/ListingDetailsPage';
import ActivationPage from "./pages/ActivationPage";
import UserSavedPage from "./pages/User/UserSavedPage";
import UserAccountPage from "./pages/User/UserAccountPage";
import UserAddListing from "./pages/User/UserAddListing";

// REDUX
import {Provider, useSelector} from 'react-redux';
import storeConfig from './redux/store';
import {ThemeProvider, Typography} from '@mui/material';

import theme from '../src/theme';
import axios from "axios";
import authService from "./services/authService";
import {sessionExpired, userLogout} from "./redux/actions/userActions";
import Redirector from "./components/common/Redirector";
import {Route as ReactRouter} from "react-router";
import ProtectedRoute from "./components/common/ProtectedRoute";
import NotFound from "./pages/NotFound";
import IntelligentSearchResultsPage from "./pages/IntelligentSearch/IntelligentSearchResultsPage";

import ReactGA from 'react-ga4';

const TRACKING_ID = 'G-F0116S7C0T';

const {store, persistor} = storeConfig();

axios.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        const originalConfig = err.config;
        const notUrls = [pathService.api.login, '/refreshToken']
        if (!notUrls.includes(originalConfig.url) && err.response) {
            // Access Token was expired
            if (err.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;
                try {
                    const rs = await axios.post(pathService.api.refreshToken, {refreshToken: authService.getRefreshToken()});
                    const {token, refreshToken} = rs.data;
                    authService.setToken(token);
                    authService.setRefreshToken(refreshToken);
                    return axios(originalConfig);
                } catch (_error) {
                    if (_error.status === 401 && _error.config.url === '/refreshToken') {
                        const {dispatch} = store;
                        dispatch(sessionExpired());
                    }
                    return Promise.reject(_error);
                }
            }
        } else {
            if (err.response?.status === 401 && err.config.url === '/refreshToken') {
                const {dispatch} = store;
                dispatch(sessionExpired());
            }
        }
        return Promise.reject(err);
    }
);

function App() {

    useEffect(() => {
        ReactGA.initialize(TRACKING_ID);
        ReactGA.send("pageview");
    }, []);

    const pages = [
        {Component: HomePage, path: pathService.pages.home, exact: true},
        {Component: LoginPage, path: pathService.pages.login, requireNonAuth: true},
        {Component: RegisterPage, path: pathService.pages.register, requireNonAuth: true},
        {Component: SearchPage, path: pathService.pages.search, exact: false},
        {Component: ListingDetailsPage, path: pathService.pages.listingDetails},
        {Component: ActivationPage, path: pathService.pages.activation, requireAuth: true},

        // USER
        {Component: UserSavedPage, path: pathService.pages.user.saved},
        {Component: UserAccountPage, path: pathService.pages.user.account, requireAuth: true, exact: true},
        {Component: UserAddListing, path: pathService.pages.user.addListing, requireAuth: true},

        // INTELLIGENT SEARCH
        {Component: IntelligentSearchResultsPage, path: pathService.pages.intelligentSearch}
    ];

    const Pages = pages.map(({path, exact, Component, requireAuth, requireNonAuth}) => (
        requireAuth || requireNonAuth
            ? <ProtectedRoute path={path} exact={exact} Component={Component} requireNonAuth={requireNonAuth}/>
            : <Route path={path} exact={exact} component={Component} key={uuid()}/>
    ));

    return (
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <Router history={history}>
                        <Redirector/>
                        <Navbar/>
                        <Switch>
                            {Pages}
                            <Route component={NotFound} />
                        </Switch>
                        <Footer/>
                    </Router>
                </PersistGate>
            </Provider>
        </ThemeProvider>
    )
}

export default App;
