import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ListingsContainer from '../listing/ListingsContainer';
import { SEARCH_DISPLAY } from '../../types/search';
import httpService from '../../services/httpService';

const LatestListings = () => {

  const [saleListings, setSaleListings] = useState([]);
  const [rentListings, setRentListing] = useState([]);

  useEffect(() => {
    httpService
      .get('/search', { params: { service: 'vanzare', offset: 0, limit: 3 }})
      .then(({ data }) => {
        const { results } = data;
        setSaleListings(results);
      });
    httpService
        .get('/search', { params: { service: 'inchirieri', offset: 0, limit: 3 }})
        .then(({ data }) => {
          const { results } = data;
          setRentListing(results);
        })
  }, []);

  return (
    <div>
      <div className="ltn__product-slider-area ltn__product-gutter pt-115 pb-70 ">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2--- text-center">
                <h1 className="section-title">Ultime proprietati listate</h1>
              </div>
            </div>
          </div>
          <div className="section-title-area ltn__section-title-2--- text-center">
            <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Proprietati de vanzare</h6>
          </div>
          <ListingsContainer listings={ saleListings } display={ SEARCH_DISPLAY.GRID } />
          <div className="section-title-area ltn__section-title-2--- text-center">
            <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Proprietati de inchiriat</h6>
          </div>
          <ListingsContainer listings={ rentListings } display={ SEARCH_DISPLAY.GRID } />
        </div>
      </div>
    </div>
  );
}

export default LatestListings;
